import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const PageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  font-family: 'Poppins', sans-serif;
  color: #333;
`;

const Header = styled.h1`
  font-size: 3em;
  color: #045AC6;
  text-align: center;
  margin-bottom: 40px;
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-bottom: 40px;
`;

const FeatureCard = styled.div`
  background-color: #f8f8f8;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const FeatureIcon = styled.div`
  font-size: 3em;
  margin-bottom: 10px;
  color: #045AC6;
`;

const FeatureTitle = styled.h2`
  font-size: 1.5em;
  color: #0077BE;
  margin-bottom: 10px;
`;

const FeatureDescription = styled.p`
  font-size: 1.1em;
  line-height: 1.6;
`;

const HomeButton = styled(Link)`
  display: inline-block;
  margin: 20px 0;
  padding: 10px 20px;
  background-color: #045AC6;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #034494;
  }
`;

const Features = () => {
  const featuresList = [
    {
      icon: '🤖',
      title: 'AI-powered Tutoring',
      description: 'Get personalized learning experiences tailored to your unique needs and learning style.'
    },
    {
      icon: '🎮',
      title: 'Educational Multiplayer Games',
      description: 'Learn while having fun with friends in our engaging multiplayer educational games.'
    },
    {
      icon: '😂',
      title: 'Custom Meme Generation',
      description: 'Boost memory retention with hilarious and educational custom memes created just for you.'
    },
    {
      icon: '🧠',
      title: 'Personalized Learning Paths',
      description: 'Follow a customized learning journey designed to maximize your progress and understanding.'
    },
    {
      icon: '⚡',
      title: 'Instant Answers',
      description: 'Get immediate responses to your questions, no matter how complex they may be.'
    },
    {
      icon: '📊',
      title: 'Progress Tracking',
      description: 'Monitor your learning journey with detailed analytics and progress reports.'
    }
  ];

  return (
    <PageContainer>
      <Header>SUPERTUTOR AI Features</Header>
      <FeatureGrid>
        {featuresList.map((feature, index) => (
          <FeatureCard key={index}>
            <FeatureIcon>{feature.icon}</FeatureIcon>
            <FeatureTitle>{feature.title}</FeatureTitle>
            <FeatureDescription>{feature.description}</FeatureDescription>
          </FeatureCard>
        ))}
      </FeatureGrid>
      <HomeButton to="/">Return to Home</HomeButton>
    </PageContainer>
  );
};

export default Features;