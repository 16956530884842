import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions'; // Removed connectFunctionsEmulator import
import { getStorage } from "firebase/storage";
import { setPersistence, browserLocalPersistence } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCEMT4B_RTlebCAG8bpmQ3szrc1rP6HvkI",
  authDomain: "aisupertutor.firebaseapp.com",
  projectId: "aisupertutor",
  storageBucket: "aisupertutor.appspot.com",
  messagingSenderId: "971437919820",
  appId: "1:971437919820:web:ad661e8784cd3c0fc12ee9"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

setPersistence(auth, browserLocalPersistence)
  .then(() => console.log("Persistence set to local"))
  .catch((error) => console.error("Error setting persistence:", error));
const firestore = getFirestore(app);
const functions = getFunctions(app); // Initialize Functions
const storage = getStorage(app);

export { app, auth, firestore, functions, storage };


// import { initializeApp } from 'firebase/app';
// import { getAuth } from 'firebase/auth';
// import { getFirestore } from 'firebase/firestore';
// import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'; // Corrected import

// const firebaseConfig = {
//   apiKey: "AIzaSyCEMT4B_RTlebCAG8bpmQ3szrc1rP6HvkI",
//   authDomain: "aisupertutor.firebaseapp.com",
//   projectId: "aisupertutor",
//   storageBucket: "aisupertutor.appspot.com",
//   messagingSenderId: "971437919820",
//   appId: "1:971437919820:web:ad661e8784cd3c0fc12ee9"
// };

// const app = initializeApp(firebaseConfig);
// const auth = getAuth(app);
// const firestore = getFirestore(app);
// const functions = getFunctions(app); // Initialize Functions

// // Conditionally use the emulator for Functions in development
// if (process.env.NODE_ENV === 'development') {
//     connectFunctionsEmulator(functions, 'localhost', 5001);
// }

// export { app, auth, firestore, functions }; // Export functions as well