import React, { useEffect } from 'react';
import styled from 'styled-components';

const WidgetContainer = styled.div`
  background-color: #ffffff;
  border: 3px solid #0066cc;
  border-radius: 16px;
  padding: 2rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: 2rem auto;
`;

// const Title = styled.h2`
//   color: #ff0000;
//   margin-bottom: 1rem;
//   font-size: 1.5rem;
//   font-weight: bold;
//   text-align: center;
// `;

const Subtitle = styled.p`
  color: #0066cc;
  margin-bottom: 1.5rem;
  font-size: 1rem;
  text-align: center;
`;

const SubstackWidget = () => {
  useEffect(() => {
    window.CustomSubstackWidget = {
      substackUrl: "https://supertutor.substack.com/",
      placeholder: "Your email here...",
      buttonText: "Sign Up",
      theme: "custom",
      colors: {
        primary: "#ff0000",
        input: "#ffffff",
        email: "#000000",
        text: "#ffffff",
      },
    };

    const script = document.createElement('script');
    script.src = "https://substackapi.com/widget.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <WidgetContainer>
      {/* <Title>Supercharge Your Learning!</Title> */}
      <Subtitle>Join our newsletter for exclusive tips, tricks, product announcements and super content</Subtitle>
      <div id="custom-substack-embed"></div>
    </WidgetContainer>
  );
};

export default SubstackWidget;