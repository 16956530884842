import React, { useRef, useState } from 'react';
import './CodeBlock.css';

const CodeBlock = ({ code }) => {
  const codeRef = useRef(null);
  const [buttonText, setButtonText] = useState('Copy Code');

  const copyCode = () => {
    const codeElement = codeRef.current;
    const range = document.createRange();
    range.selectNode(codeElement);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
    setButtonText('Copied!');
    setTimeout(() => {
      setButtonText('Copy Code');
    }, 2000);
  };

  return (
    <div className="code-block">
      <pre ref={codeRef}>{code}</pre>
      <button onClick={copyCode}>{buttonText}</button>
    </div>
  );
};

export default CodeBlock;

