import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const TimerContainer = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin: 10px 0;
  color: ${props => props.timeLeft <= 5 ? 'red' : 'black'};
`;

const Timer = ({ duration, onTimeUp, isRunning }) => {
  const [timeLeft, setTimeLeft] = useState(duration);

  useEffect(() => {
    setTimeLeft(duration);
  }, [duration]);

  useEffect(() => {
    if (!isRunning) return;
  
    const timerId = setInterval(() => {
      setTimeLeft(prevTime => {
        if (prevTime <= 1) {
          clearInterval(timerId);
          onTimeUp();
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
  
    return () => clearInterval(timerId);
  }, [isRunning, onTimeUp, duration]);

  return <TimerContainer timeLeft={timeLeft}>{timeLeft} seconds</TimerContainer>;
};

export default Timer;