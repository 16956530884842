import React, { useState } from 'react';

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqItems = [
    {
      question: "What can Supertutor do for me?",
      answer: "Supertutor is your AI-powered learning companion! It provides personalized tutoring, creates custom educational memes to boost memory retention, offers multiplayer trivia and imagination games for fun learning, and gives you unlimited access to GPT-4o for in-depth explanations on any topic. Whether you're struggling with a difficult concept or want to foster a more creative inspiring learning environment, Supertutor is here for you!"
    },
    {
      question: "How does Supertutor work?",
      answer: "It's simple and fun! Just ask Supertutor any question or topic you want to learn about. Our AI analyzes your query and provides tailored explanations, generates relevant memes, and even creates interactive quizzes. It's like having a super-smart, always-available tutor in your pocket, ready to help you learn in the most engaging way possible."
    },
    {
      question: "Is my data safe?",
      answer: "Absolutely! We take your privacy and data security very seriously. All your personal information and learning data is encrypted and stored securely. We never share your data with third parties. Your learning journey is yours alone, and we're committed to keeping it that way."
    },
    {
      question: "How do I get started?",
      answer: "Getting started with Supertutor is a breeze! First, sign up for an account on our website or app. Once you're in, try asking Supertutor a question about something you're learning. You'll instantly see how it breaks down complex topics into easy-to-understand explanations. Next, challenge yourself with a quick trivia game or explore our meme-making feature. Before you know it, you'll be learning faster and having more fun than ever before!"
    },
    {
        question: "Why was Supertutor created?",
        answer: "Supertutor was born from a passion to make learning accessible, enjoyable, and effective for everyone. We recognized that traditional education often struggles to cater to diverse learning styles and keep pace with our digital world. That's why we've combined cutting-edge AI with interactive learning techniques. <br /><br/> For students, Supertutor adapts to your needs, whether you're tackling homework, aiming to excel, or simply curious about the world. It transforms learning into a delightful, empowering experience.<br /><br />For teachers, Supertutor is a powerful classroom ally. Our imagination game fosters creative expression, helping educators craft more engaging and interactive lessons. With tools to generate custom materials, quizzes, and multimedia content, Supertutor helps captivate students and spark their imagination.<br /><br />Whether you're a student, a professional upskilling, a teacher revolutionizing your classroom, or just an eager learner, Supertutor is here to ignite a lifelong love for learning and unlock the creative potential in everyone."
      }
  ];

  const toggleQuestion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div style={{ marginTop: '40px', fontFamily: 'Arial, sans-serif', maxWidth: '800px', margin: '0 auto' }}>
      <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Frequently Asked Questions:</h2>
      {faqItems.map((item, index) => (
        <div key={index} style={{ marginBottom: '15px' }}>
          <div 
            onClick={() => toggleQuestion(index)}
            style={{
              backgroundColor: 'white',
              padding: '15px',
              borderRadius: '8px',
              cursor: 'pointer',
              boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <h3 style={{ margin: 0 }}>{item.question}</h3>
            <svg 
              width="20" 
              height="20" 
              viewBox="0 0 20 20" 
              fill="none" 
              xmlns="http://www.w3.org/2000/svg"
              style={{
                transform: openIndex === index ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s ease'
              }}
            >
              <path d="M5 7.5L10 12.5L15 7.5" stroke="#999999" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </div>
          {openIndex === index && (
            <div 
              style={{
                backgroundColor: 'white',
                padding: '15px',
                textAlign: 'left',
                borderRadius: '0 0 8px 8px',
                marginTop: '2px',
                boxShadow: '0 2px 5px rgba(0,0,0,0.1)'
              }}
            >
              <div 
                style={{ lineHeight: '1.6', color: '#333' }}
                dangerouslySetInnerHTML={{ __html: item.answer.replace(/\n\s*\n/g, '<br><br>') }}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FAQ;