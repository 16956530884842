import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, addDoc, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { auth, firestore } from '../firebase';
import './MyFlashcards.css';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

function MyFlashcards() {
  const [decks, setDecks] = useState([]);
  const [selectedDeck, setSelectedDeck] = useState(null);
  const [flashcards, setFlashcards] = useState([]);
  const [newCard, setNewCard] = useState({ 
    question: '', 
    answer: '', 
    deckId: '', 
    newDeckTitle: '' 
  });
  const [newDeck, setNewDeck] = useState({ title: '' });
  const [showNewDeckForm, setShowNewDeckForm] = useState(false);
  const [flippedCards, setFlippedCards] = useState({});
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [showNewCardForm, setShowNewCardForm] = useState(false);
  const [editingCard, setEditingCard] = useState(null);

  useEffect(() => {
    fetchDecks();
  }, []);

  useEffect(() => {
    if (selectedDeck) {
      fetchFlashcards(selectedDeck.id);
    }
  }, [selectedDeck]);

  const fetchDecks = async () => {
    if (!auth.currentUser) return;
    
    const q = query(collection(firestore, 'users', auth.currentUser.uid, 'decks'));
    const snapshot = await getDocs(q);
    
    // Get decks with card counts
    const deckPromises = snapshot.docs.map(async (doc) => {
      const cardsQuery = query(collection(firestore, 'users', auth.currentUser.uid, 'decks', doc.id, 'flashcards'));
      const cardsSnapshot = await getDocs(cardsQuery);
      
      return {
        id: doc.id,
        ...doc.data(),
        cardCount: cardsSnapshot.size
      };
    });

    const deckList = await Promise.all(deckPromises);
    setDecks(deckList);
  };

  const handleCreateDeck = async (e) => {
    e.preventDefault();
    if (!newDeck.title.trim()) return;

    try {
      const deckData = {
        title: newDeck.title,
        createdAt: new Date(),
        userId: auth.currentUser.uid
      };

      const docRef = await addDoc(
        collection(firestore, 'users', auth.currentUser.uid, 'decks'),
        deckData
      );

      setDecks([...decks, { id: docRef.id, ...deckData }]);
      setNewDeck({ title: '' });
      setShowNewDeckForm(false);
    } catch (error) {
      console.error('Error creating deck:', error);
    }
  };

  const fetchFlashcards = async (deckId) => {
    if (!auth.currentUser || !deckId) return;
    
    const q = query(collection(firestore, 'users', auth.currentUser.uid, 'decks', deckId, 'flashcards'));
    const snapshot = await getDocs(q);
    const cards = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setFlashcards(cards);
  };

  const handleAddCard = async (e) => {
    e.preventDefault();
    if (!newCard.question.trim() || !newCard.answer.trim()) return;
    
    try {
      let targetDeckId = newCard.deckId;

      // If creating new deck
      if (newCard.deckId === 'new') {
        if (!newCard.newDeckTitle.trim()) return;
        
        // Create new deck
        const deckData = {
          title: newCard.newDeckTitle,
          createdAt: new Date(),
          userId: auth.currentUser.uid
        };

        const deckRef = await addDoc(
          collection(firestore, 'users', auth.currentUser.uid, 'decks'),
          deckData
        );
        
        targetDeckId = deckRef.id;
        setDecks([...decks, { id: targetDeckId, ...deckData }]);
      }

      // Add card to deck
      const cardData = {
        question: newCard.question,
        answer: newCard.answer,
        createdAt: new Date(),
        userId: auth.currentUser.uid
      };

      const docRef = await addDoc(
        collection(firestore, 'users', auth.currentUser.uid, 'decks', targetDeckId, 'flashcards'),
        cardData
      );

      if (targetDeckId === selectedDeck?.id) {
        setFlashcards([...flashcards, { id: docRef.id, ...cardData }]);
      }

      setNewCard({ question: '', answer: '', deckId: '', newDeckTitle: '' });
      setShowNewCardForm(false);
    } catch (error) {
      console.error('Error adding flashcard:', error);
    }
  };

  const handleDeleteCard = async (cardId) => {
    try {
      await deleteDoc(doc(firestore, 'users', auth.currentUser.uid, 'flashcards', cardId));
      setFlashcards(prevCards => prevCards.filter(card => card.id !== cardId));
      
      if (flashcards.length <= 1) {
        setCurrentCardIndex(0);
      } else if (currentCardIndex === flashcards.length - 1) {
        setCurrentCardIndex(prev => prev - 1);
      }
    } catch (error) {
      console.error('Error deleting flashcard:', error);
    }
  };

  const handleFlipCard = (cardId) => {
    setFlippedCards(prev => ({
      ...prev,
      [cardId]: !prev[cardId]
    }));
  };

  const navigateCards = (direction) => {
    if (direction === 'next') {
      setCurrentCardIndex((prev) => 
        prev === flashcards.length - 1 ? 0 : prev + 1
      );
    } else {
      setCurrentCardIndex((prev) => 
        prev === 0 ? flashcards.length - 1 : prev - 1
      );
    }
  };

  const handleEditCard = async (e) => {
    e.preventDefault();
    try {
      await updateDoc(
        doc(firestore, 'users', auth.currentUser.uid, 'flashcards', editingCard.id),
        {
          question: editingCard.question,
          answer: editingCard.answer
        }
      );

      setFlashcards(prevCards => 
        prevCards.map(card => 
          card.id === editingCard.id ? { ...card, ...editingCard } : card
        )
      );
      setEditingCard(null);
    } catch (error) {
      console.error('Error updating flashcard:', error);
      alert('Failed to update flashcard');
    }
  };

  const currentCard = flashcards[currentCardIndex];

  return (
    <div className="flashcards-container">
      <div className="deck-selection">
        <button 
          className="floating-add-button"
          onClick={() => setShowNewCardForm(true)}
        >
          +
        </button>
        
        <div className="deck-list">
          {decks.map(deck => (
            <button
              key={deck.id}
              className={`deck-button ${selectedDeck?.id === deck.id ? 'selected' : ''}`}
              onClick={() => setSelectedDeck(deck)}
            >
              <div>
                <div className="deck-title">{deck.title}</div>
                <div className="deck-info">
                  {deck.cardCount} {deck.cardCount === 1 ? 'card' : 'cards'} • Click to view
                </div>
              </div>
              <div className="deck-preview" />
            </button>
          ))}
        </div>
      </div>

      {showNewDeckForm && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Create New Deck</h3>
            <form onSubmit={handleCreateDeck}>
              <div className="form-group">
                <input
                  type="text"
                  value={newDeck.title}
                  onChange={(e) => setNewDeck({ title: e.target.value })}
                  placeholder="Deck Title"
                />
              </div>
              <div className="form-actions">
                <button type="submit">Create Deck</button>
                <button 
                  type="button" 
                  onClick={() => {
                    setShowNewDeckForm(false);
                    setNewDeck({ title: '' });
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {showNewCardForm && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Add New Flashcard</h3>
            <form onSubmit={handleAddCard}>
              <div className="form-group">
                <select
                  value={newCard.deckId}
                  onChange={(e) => setNewCard({ 
                    ...newCard, 
                    deckId: e.target.value,
                    newDeckTitle: '' 
                  })}
                >
                  <option value="">Select a deck or create new</option>
                  {decks.map(deck => (
                    <option key={deck.id} value={deck.id}>
                      {deck.title}
                    </option>
                  ))}
                  <option value="new">+ Create New Deck</option>
                </select>
              </div>

              {newCard.deckId === 'new' && (
                <div className="form-group">
                  <input
                    type="text"
                    value={newCard.newDeckTitle}
                    onChange={(e) => setNewCard({ 
                      ...newCard, 
                      newDeckTitle: e.target.value 
                    })}
                    placeholder="New Deck Title"
                  />
                </div>
              )}

              <div className="form-group">
                <input
                  type="text"
                  value={newCard.question}
                  onChange={(e) => setNewCard({ ...newCard, question: e.target.value })}
                  placeholder="Front side text"
                />
              </div>
              <div className="form-group">
                <textarea
                  value={newCard.answer}
                  onChange={(e) => setNewCard({ ...newCard, answer: e.target.value })}
                  placeholder="Back side text"
                />
              </div>
              <div className="form-actions">
                <button type="submit">Add Card</button>
                <button 
                  type="button" 
                  onClick={() => {
                    setShowNewCardForm(false);
                    setNewCard({ question: '', answer: '', deckId: '', newDeckTitle: '' });
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {flashcards.length > 0 ? (
        <div className="flashcards-carousel">
          <button 
            className="nav-button prev" 
            onClick={() => navigateCards('prev')}
            disabled={flashcards.length <= 1}
          >
            <FaChevronLeft />
          </button>

          <div className="flashcard">
            {currentCard && (
              <div 
                className={`flashcard-inner ${flippedCards[currentCard.id] ? 'flipped' : ''}`}
                onClick={() => handleFlipCard(currentCard.id)}
              >
              <div className="flashcard-front">
  <p>{currentCard.question}</p>
  <div className="card-actions" onClick={(e) => e.stopPropagation()}>
    <button 
      className="edit-button"
      onClick={(e) => {
        e.stopPropagation();
        setEditingCard({ ...currentCard });
      }}
    >
      Edit
    </button>
    <button 
      className="delete-button"
      onClick={(e) => {
        e.stopPropagation();
        handleDeleteCard(currentCard.id);
      }}
    >
      Delete
    </button>
  </div>
</div>
                <div className="flashcard-back">
                  <p>{currentCard.answer}</p>
                </div>
              </div>
            )}
          </div>

          <button 
            className="nav-button next" 
            onClick={() => navigateCards('next')}
            disabled={flashcards.length <= 1}
          >
            <FaChevronRight />
          </button>
        </div>
      ) : (
        <div className="no-cards">
          {selectedDeck ? (
            <p>No flashcards yet in this deck. Click the + button to add your first card!</p>
          ) : (
            <p>Select a deck or create a new one to get started!</p>
          )}
        </div>
      )}

      {editingCard && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Edit Flashcard</h3>
            <form onSubmit={handleEditCard}>
              <div className="form-group">
                <input
                  type="text"
                  value={editingCard.question}
                  onChange={(e) => setEditingCard({
                    ...editingCard,
                    question: e.target.value
                  })}
                  placeholder="Front side text"
                />
              </div>
              <div className="form-group">
                <textarea
                  value={editingCard.answer}
                  onChange={(e) => setEditingCard({
                    ...editingCard,
                    answer: e.target.value
                  })}
                  placeholder="Back side text"
                />
              </div>
              <div className="form-actions">
                <button type="submit">Save Changes</button>
                <button 
                  type="button" 
                  onClick={() => setEditingCard(null)}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default MyFlashcards;