import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaShare } from 'react-icons/fa'; // Add this import
import SharePopup from './SharePopup';

import { FaLock, FaCheck, FaPlus } from 'react-icons/fa';
import { collection, doc, setDoc, writeBatch, getDocs, getDoc, query, where, onSnapshot } from 'firebase/firestore';
import { firestore } from './firebase';
import QuestPlay from './QuestPlay';
import Modal from './Modal';
import CreateQuest from './CreateQuest';
import { FaEllipsisV, FaEdit, FaTrash } from 'react-icons/fa';
// import { deleteDoc } from 'firebase/firestore';
import { FaEye } from 'react-icons/fa';
import questsImage from './quests.png'; // Adjust the path as necessary
 
const SearchBar = styled.input`
width: 100%;
max-width: 400px; // Adjust as needed
padding: 10px;
margin: 20px 0; // Space above and below
border: 1px solid #ccc;
border-radius: 5px;
font-size: 16px;

&:focus {
  border-color: #007BFF; // Change border color on focus
  outline: none;
}
`;
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column; // Stack children vertically
  align-items: center; // Center children horizontally
  margin-bottom: 30px;
  padding-top: 20px;
  position: relative;
`;

const QuestsLogo = styled.img`
  width: 7%;
  height: auto;

  @media (max-width: 768px) {
    width: 20%; // Adjust this value as needed for mobile
    max-width: 200px; // Optional: set a maximum width
  }
`;

export const BackButton = styled(Link)`
  position: fixed;
  top: 20px;
  left: 20px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 10000;  // Increased z-index to ensure it's above modals
  transition: all 0.3s ease;

  img {
    width: 50px;  // Adjust this value as needed
    height: auto;
  }

  &:hover {
    transform: scale(1.1);
  }
`;

const MenuButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  color: #333;
  cursor: pointer;
  z-index: 10;
  opacity: 1; // Changed from 0 to 1 to always be visible
  transition: opacity 0.3s ease;
`;

const MenuOptions = styled.div`
  position: absolute;
  top: 40px;
  right: 10px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  display: ${props => props.isOpen ? 'block' : 'none'};
`;


const MenuOption = styled.div`
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #f0f0f0;
  }

  svg {
    margin-right: 5px;
  }
`;


const PublicIndicator = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #4CAF50;
  color: white;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
`;


const QuestImage = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  margin-top: 20px; // Add margin at the top of the image
  margin-bottom: 16px;
`;

const QuestContainer = styled.div`
  padding: 20px 0;
  max-width: 100%;
  margin: auto;
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  font-weight: 400;
`;

const QuestScrollContainer = styled.div`
  overflow-x: auto;
  white-space: nowrap;
  padding: 20px 0;
  scrollbar-width: thin;
  scrollbar-color: #86868b #f5f5f7;
  
  &::-webkit-scrollbar {
    height: 8px;
  }
  
  &::-webkit-scrollbar-track {
    background: #f5f5f7;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #86868b;
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: #1d1d1f;
  }
`;


const QuestWrapper = styled.div`
  display: inline-flex;
  padding: 0 20px;
`;

 
const CreateButton = styled.button`
  position: fixed;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  padding: 0;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 24px;
  font-weight: 600;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  &:hover {
    background-color: #45a049;
    transform: scale(1.1);
    box-shadow: 0 6px 12px rgba(0,0,0,0.15);
  }

  svg {
    margin: 0;
  }
`;

const CreateQuestButton = styled.button`
  margin-top: 20px;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  background-color: #007BFF; // Different color for distinction
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: #0056b3; // Darker shade on hover
    transform: translateY(-2px); // Slight lift effect
  }
`;

const NoQuestsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; // Center horizontally
  justify-content: center; // Center vertically
  height: 100vh; // Full height to center vertically
  text-align: center; // Center text
`;


const ToggleHiddenButton = styled(CreateButton)`
  right: 70px;
  background-color: ${props => props.active ? '#4CAF50' : '#f44336'};

  &:hover {
    background-color: ${props => props.active ? '#45a049' : '#d32f2f'};
  }
`;

const QuestCard = styled.div`
  background-color: #ffffff;
  border-radius: 18px;
  padding: 24px;
  margin-right: 24px;
  box-shadow: 0 4px 20px rgba(0,0,0,0.05);
  transition: all 0.3s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 280px;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 6px 30px rgba(0,0,0,0.1);
  }
`;


const QuestName = styled.h3`
  font-size: 20px;
  color: #1d1d1f;
  margin-bottom: 16px;
  text-align: center;
  font-weight: 600;
  max-width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  overflow: visible;
  line-height: 1.2;
  max-height: 3.6em;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  padding-bottom: 13px; // Add padding at the bottom of the title
`;
const LevelMap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
const LevelDot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 25px 0; // Increased from 24px to 40px
  position: relative;
  width: 100%;
`;


const DotCircle = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: ${props => props.completed ? '#06c' : props.unlocked ? '#f5f5f7' : '#e3e3e3'};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: ${props => props.completed ? '#ffffff' : '#1d1d1f'};
  box-shadow: ${props => props.unlocked ? '0 2px 8px rgba(0,0,0,0.1)' : 'none'};
  transition: all 0.3s ease;

  &:hover {
    transform: ${props => props.unlocked ? 'scale(1.1)' : 'none'};
  }
`;

const LevelTitle = styled.span`
  font-size: 12px;
  text-align: center;
  max-width: 100%;
  word-wrap: break-word;
  margin-top: 4px;
  color: #86868b;
`;

const LevelNumber = styled.span`
  font-weight: 600;
  font-size: 16px;
`;

const StartTooltip = styled.div`
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff;
  color: #1d1d1f;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  white-space: nowrap;
  z-index: 10;
`;

 
const Quests = ({ user }) => {
   const [quests, setQuests] = useState([]);
  const [userProgress, setUserProgress] = useState({});
  const [selectedQuest, setSelectedQuest] = useState(null);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState(null);
  const [editingQuest, setEditingQuest] = useState(null);
  const [hiddenQuests, setHiddenQuests] = useState([]);
  const [showHiddenQuests, setShowHiddenQuests] = useState(false);
  // const [_shareLink, setShareLink] = useState('');

  const [searchQuery, setSearchQuery] = useState('');

  const [sharePopupOpen, setSharePopupOpen] = useState(false);
  const [currentShareQuest, setCurrentShareQuest] = useState(null);

  const generateShareLink = (questId) => {
    const baseUrl = window.location.origin;
    return `${baseUrl}/shared-quest/${questId}`;
  };

  const handleShareQuest = (quest, e) => {
    e.stopPropagation();
    console.log("Attempting to share quest:", quest); // Log the entire quest object
  
    if (typeof quest === 'string') {
      // If quest is just an ID string, find the full quest object
      const questToShare = getVisibleQuests().find(q => q.id === quest);
      if (questToShare) {
        setCurrentShareQuest(questToShare);
        setSharePopupOpen(true);
        setOpenMenu(null);
      } else {
        console.error("Quest not found for ID:", quest);
      }
    } else if (quest && typeof quest === 'object') {
      // If quest is already an object, use it directly
      setCurrentShareQuest(quest);
      setSharePopupOpen(true);
      setOpenMenu(null);
    } else {
      console.error("Invalid quest data:", quest);
    }
  };
  useEffect(() => {
    if (user) {
      const questsCollection = collection(firestore, 'quests');
  
      // Query to fetch quests created by the user
      const userQuestsQuery = query(questsCollection, where('createdBy.uid', '==', user.uid));
      
      // Query to fetch all public quests
      const publicQuestsQuery = query(questsCollection, where('isPublic', '==', true));
  
      // Subscribe to the user's quests
      const unsubscribeUserQuests = onSnapshot(userQuestsQuery, (snapshot) => {
        const fetchedUserQuests = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          levels: doc.data().levels?.map(level => ({
            ...level,
            questions: level.questions?.map(question => ({
              ...question,
              media: question.media || { type: null, content: '' },
              explanation: question.explanation || ''
            })) || []
          })) || []
        }));
        
        setQuests(prevQuests => {
          const nonUserQuests = prevQuests.filter(quest => quest.createdBy?.uid !== user.uid);
          return [...fetchedUserQuests, ...nonUserQuests];
        });
      });
  
      // Subscribe to public quests
      const unsubscribePublicQuests = onSnapshot(publicQuestsQuery, (snapshot) => {
        const fetchedPublicQuests = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          levels: doc.data().levels?.map(level => ({
            ...level,
            questions: level.questions?.map(question => ({
              ...question,
              media: question.media || { type: null, content: '' },
              explanation: question.explanation || ''
            })) || []
          })) || []
        }));
  
        setQuests(prevQuests => {
          const userQuests = prevQuests.filter(quest => quest.createdBy?.uid === user.uid);
          const newPublicQuests = fetchedPublicQuests.filter(publicQuest => 
            !userQuests.some(userQuest => userQuest.id === publicQuest.id)
          );
          return [...userQuests, ...newPublicQuests];
        });
      });
  
      // Fetch user data for progress and hidden quests
      const fetchUserData = async () => {
        const userDoc = await getDoc(doc(firestore, 'users', user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserProgress(userData.gameProgress || {});
          setHiddenQuests(userData.hiddenQuests || []);
        }
      };
  
      fetchUserData();
  
      // Cleanup function to unsubscribe from both snapshot listeners
      return () => {
        unsubscribeUserQuests();
        unsubscribePublicQuests();
      };
    }
  }, [user]);
  
  const getVisibleQuests = () => {
    return quests
      .filter(quest => 
        (quest.createdBy?.uid === user?.uid || 
        (quest.isPublic && (!hiddenQuests.includes(quest.id) || showHiddenQuests))) &&
        (quest.name?.toLowerCase().includes(searchQuery?.toLowerCase()) ?? true)
      )
      .sort((a, b) => {
        if (!a.name || !b.name) return 0;
        const aStartsWithQuery = a.name.toLowerCase().startsWith(searchQuery?.toLowerCase() ?? '');
        const bStartsWithQuery = b.name.toLowerCase().startsWith(searchQuery?.toLowerCase() ?? '');
        if (aStartsWithQuery && !bStartsWithQuery) return -1;
        if (!aStartsWithQuery && bStartsWithQuery) return 1;
        return a.name.localeCompare(b.name);
      });
  };
  // const getVisibleQuests = (quests, filters) => {
  //   return quests.filter(quest => {
  //     const matchesSearch = quest.name.toLowerCase().includes(filters.searchTerm.toLowerCase());
  //     const matchesCategory = quest.category.toLowerCase() === filters.category.toLowerCase();
  //     const matchesDifficulty = quest.difficulty.toLowerCase() === filters.difficulty.toLowerCase();
  //     return matchesSearch && matchesCategory && matchesDifficulty;
  //   });
  // };


  const toggleHideQuest = async (questId, e) => {
    e.stopPropagation();
    const userRef = doc(firestore, 'users', user.uid);
    const newHiddenQuests = hiddenQuests.includes(questId)
      ? hiddenQuests.filter(id => id !== questId)
      : [...hiddenQuests, questId];
  
    await setDoc(userRef, { hiddenQuests: newHiddenQuests }, { merge: true });
    setHiddenQuests(newHiddenQuests);
  };
  const handleQuestSelect = (quest, startLevel = 1) => {
    setSelectedQuest({...quest, startLevel});
  };

  const handleMenuClick = (questId, e) => {
    e.stopPropagation();
    setOpenMenu(openMenu === questId ? null : questId);
  };

  const handleEditQuest = (quest, e) => {
    e.stopPropagation();
    setEditingQuest(quest);
    setIsCreateModalOpen(true);
    setOpenMenu(null);
  };

  const handleCloseQuestPlay = () => {
    setSelectedQuest(null);
    // Re-fetch user progress
    const fetchUserProgress = async () => {
      const userDoc = await getDoc(doc(firestore, 'users', user.uid));
      if (userDoc.exists()) {
        setUserProgress(userDoc.data().gameProgress || {});
      }
    };
    fetchUserProgress();
  };

  const handleDeleteQuest = async (questId, e) => {
    e.stopPropagation();
    if (window.confirm('Are you sure you want to delete this quest?')) {
      try {
        const questRef = doc(firestore, 'quests', questId);
        const questDoc = await getDoc(questRef);
        
        if (!questDoc.exists()) {
          throw new Error('Quest not found');
        }
        
        const questData = questDoc.data();
        
        if (questData.createdBy.uid !== user.uid || questData.isPublic) {
          throw new Error('You do not have permission to delete this quest');
        }
  
        const batch = writeBatch(firestore);
  
        // Delete all levels
        const levelsRef = collection(firestore, 'quests', questId, 'levels');
        const levelsSnapshot = await getDocs(levelsRef);
        levelsSnapshot.forEach((doc) => {
          batch.delete(doc.ref);
        });
  
        // Delete the main quest document
        batch.delete(questRef);
  
        await batch.commit();
        setOpenMenu(null);
      } catch (error) {
        console.error('Error deleting quest:', error);
        alert('Failed to delete quest: ' + error.message);
      }
    }
  };


  const handleCreateQuest = () => {
    setEditingQuest(null);
    setIsCreateModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsCreateModalOpen(false);
    setEditingQuest(null);
  };

  return (
    <QuestContainer>
    <BackButton to="/">
    <img src="/supertutorlogo.png" alt="SuperTutor Logo" />
      </BackButton>
      <TitleContainer>
  {/* <Title>Super Quests</Title> */}
  <QuestsLogo src={questsImage} alt="Quests" />

  <SearchBar 
    type="text" 
    placeholder="Search quests..." 
    value={searchQuery} 
    onChange={(e) => setSearchQuery(e.target.value)} 
  />

  <CreateButton onClick={handleCreateQuest}>
    <FaPlus /> 
  </CreateButton>
  {hiddenQuests.length > 0 && (
  <ToggleHiddenButton 
  onClick={() => setShowHiddenQuests(!showHiddenQuests)}
  active={showHiddenQuests ? 'true' : 'false'}
>
  <FaEye />
</ToggleHiddenButton>
  )}
</TitleContainer>
      {isCreateModalOpen && (
        <CreateQuest
          user={user}
          onClose={handleCloseModal}
          editingQuest={editingQuest}
        />
      )}
 {getVisibleQuests().length === 0 ? (
  <NoQuestsContainer>
     <CreateQuestButton onClick={handleCreateQuest}>
      Create Your First Quest
    </CreateQuestButton>
  </NoQuestsContainer>
) : (
  <QuestScrollContainer>
    <QuestWrapper>
      {getVisibleQuests().map(quest => (
        <QuestCard 
          key={quest.id} 
          onClick={() => handleQuestSelect(quest)}
          levelCount={quest.levels.length}
        >
          {quest.isPublic && <PublicIndicator>Public</PublicIndicator>}
          <MenuButton onClick={(e) => handleMenuClick(quest.id, e)}>
                <FaEllipsisV />
              </MenuButton>
              <MenuOptions isOpen={openMenu === quest.id}>
                {quest.createdBy.uid === user.uid ? (
                  <>
                    <MenuOption onClick={(e) => handleEditQuest(quest, e)}>
                      <FaEdit /> Edit
                    </MenuOption>
                    <MenuOption onClick={(e) => handleDeleteQuest(quest.id, e)}>
                      <FaTrash /> Delete
                    </MenuOption>
                    {/* <MenuOption onClick={(e) => handleShareQuest(quest.id, e)}>
                      <FaShare /> Share
                    </MenuOption> */}
                    <MenuOption onClick={(e) => handleShareQuest(quest, e)}>
  <FaShare /> Share
</MenuOption>
                  </>
                ) : quest.isPublic && (
                  <>
                    <MenuOption onClick={(e) => toggleHideQuest(quest.id, e)}>
                      <FaEye /> {hiddenQuests.includes(quest.id) ? 'Unhide' : 'Hide'}
                    </MenuOption>
                    <MenuOption onClick={(e) => handleShareQuest(quest.id, e)}>
                      <FaShare /> Share
                    </MenuOption>
                  </>
                )}
              </MenuOptions>
          <QuestName>{quest.name}</QuestName>
          {quest.imageUrl && <QuestImage src={quest.imageUrl} />}
          <LevelMap>
            {quest.levels.map((level, index) => {
              const progress = userProgress[quest.id]?.levelsCompleted || [];
              const completed = progress.includes(level.levelNumber);
              const unlocked = level.levelNumber === 1 || progress.includes(level.levelNumber - 1);
              const isCurrentLevel = unlocked && !completed;
              const isNextLevel = !unlocked && progress.includes(level.levelNumber - 1);
              return (
                <LevelDot 
                  key={index}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (unlocked) {
                      handleQuestSelect(quest, level.levelNumber);
                    }
                  }}
                >
                  <DotCircle completed={completed} unlocked={unlocked}>
                    {completed ? (
                      <FaCheck />
                    ) : (
                      <LevelNumber>{level.levelNumber}</LevelNumber>
                    )}
                  </DotCircle>
                  <LevelTitle>{level.levelTitle}</LevelTitle>
                  {isCurrentLevel && <StartTooltip>Start</StartTooltip>}
                  {isNextLevel && <StartTooltip>Complete previous levels first</StartTooltip>}
                  {!unlocked && !completed && <FaLock style={{ position: 'absolute', top: -5, right: -5, fontSize: '12px', color: '#86868b' }} />}
                </LevelDot>
              );
            })}
          </LevelMap>
        </QuestCard>
      ))}
    </QuestWrapper>
  </QuestScrollContainer>
  
)}
    {sharePopupOpen && currentShareQuest && (
        <SharePopup
          quest={currentShareQuest}
          shareLink={generateShareLink(currentShareQuest.id)}
          onClose={() => setSharePopupOpen(false)}
        />
      )}
      <Modal isOpen={isCreateModalOpen} onClose={handleCloseModal}>
        <CreateQuest user={user} onClose={handleCloseModal} editingQuest={editingQuest} />
      </Modal>
      {selectedQuest && (
        <Modal isOpen={true} onClose={handleCloseQuestPlay}>
          <QuestPlay 
            quest={selectedQuest} 
            user={user} 
            onBack={handleCloseQuestPlay} 
          />
        </Modal>
      )}
    </QuestContainer>
  );
};

export default Quests;