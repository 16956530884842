import React, { useState, useEffect, useRef } from 'react';

const ManageAccountPopup = ({ show, onClose, userName }) => {
  const [showManageSubscription, setShowManageSubscription] = useState(false);
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (show) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [show, onClose]);

  useEffect(() => {
    setShowManageSubscription(false);
  }, [show]);

  const handleManageSubscription = () => {
    setShowManageSubscription(true);
  };

  const handleStripeLink = () => {
    window.open('https://billing.stripe.com/p/login/aEU2bB1oN1lp6Y04gg', '_blank');
  };

  const handleEmailFounder = () => {
    const email = 'adam@aisupertutor.org';
    const subject = encodeURIComponent('Meet Regarding Supertutor Improvement');
    const emailBody = encodeURIComponent("Hello Adam, \nI would like to discuss potential improvements for Supertutor.");
    window.open(`mailto:${email}?subject=${subject}&body=${emailBody}`, '_blank');
  };

  if (!show) return null;

  return (
    <div className="popup-overlay" style={overlayStyles} onClick={onClose}>
      <div className="popup" style={popupStyles} onClick={(e) => e.stopPropagation()} ref={popupRef}>
        {!showManageSubscription ? (
          <>
            <h2>Hi {userName},</h2>
            <p>
              Thank you for using supertutor and unlocking your learning superpowers:)  Please share you suggestions, feedback or ideas you have to improve Supertutor and we will work with you to make Supertutor even more valuable to you!
            </p>
            <div className="popup-buttons">
              <button onClick={onClose}>Talk to Supertutor more</button>
              <button onClick={handleManageSubscription}>Manage Subscription</button>
            </div>
          </>
        ) : (
          <>
            <h2>Stick with Supertutor offer:)</h2>
            <p>
              We'd love to make supertutor work for you. If you have any feedback or ideas for how we can improve supertutor to make it more useful for you, we are happy to get on a call or meet with you to hear your ideas, help bring them to life and make your experience more wonderful.
              Claim this offer and receive an additional special reward for your feedback. Thank you for being a part of this journey to solve your difficult problems and make learning more fun.
            </p>
            <div className="popup-buttons">
              <button 
                onClick={handleEmailFounder}
                style={{
                  backgroundColor: '#98FB98', // Green background
                  color: 'black',              // White text
                  padding: '10px 20px',        // Padding around the text
                  border: '1px solid black', // Thin black border
                  borderRadius: '5px',         // Rounded corners
                  cursor: 'pointer',           // Pointer cursor on hover
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Shadow effect
                  transition: 'all 0.3s ease'  // Smooth transition for hover effects
                }}
              >
                Accept special offer:)
              </button>
              <button onClick={handleStripeLink}>Manage Subscription</button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const overlayStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
};

const popupStyles = {
  backgroundColor: 'white',
  padding: '20px',
  borderRadius: '10px',
  maxWidth: '600px',
  width: '100%',
  maxHeight: '80vh',
  overflowY: 'auto',
};

export default ManageAccountPopup;
