import React, { useState } from 'react';

const OpenAIStream = () => {
  const [question, setQuestion] = useState('');
  const [assistantResponse, setAssistantResponse] = useState('');

  const readStream = async (reader) => {
    const { done, value } = await reader.read();
    if (done) {
      console.log("Stream completed");
      return;
    }
    const chunk = new TextDecoder('utf-8').decode(value, { stream: true });
    setAssistantResponse(prev => prev + chunk);
    readStream(reader);
  };

  const handleAsk = async () => {
    const response = await fetch('https://us-central1-aisupertutor.cloudfunctions.net/openaistream', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ question }),
    });

    if (response.ok) {
      const reader = response.body.getReader();
      setAssistantResponse(''); // Clear previous response
      readStream(reader);
    } else {
      console.error('Error calling OpenAI:', response.statusText);
    }
  };

  return (
    <div>
      <h1>OpenAI Stream Example</h1>
      <textarea
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
        rows="4"
        cols="50"
      />
      <button onClick={handleAsk}>Ask</button>
      <div>{assistantResponse}</div>
    </div>
  );
};

export default OpenAIStream;
