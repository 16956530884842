const termsOfService = `
  [Supertutor Terms, Conditions and Privacy Policy


    Agreement between User and Supertutor
    Welcome to https://aisupertutor.web.app/. The website (the "Site") is comprised of various web pages operated by Tutto LLC. https://aisupertutor.web.app/ is offered to you conditioned on your acceptance without modification of the terms, conditions, and notices contained herein (the "Terms"). Your use of https://aisupertutor.web.app/ constitutes your agreement to all such Terms. Please read these terms carefully, and keep a copy of them for your reference. https://aisupertutor.web.app/ is a platform connecting students with peer tutors and study partners. Supertutor, an AI Tutor is accessed via a subscription fee. By agreeing to these Terms and Conditions, you acknowledge that Supertutor is meant to assist in learning but does not guarantee academic success.
    
    Privacy
    Your use of https://aisupertutor.web.app/ is subject to the privacy policy. Please review our Privacy Policy, which also governs the Site and informs users of our data collection practices.
    
    Electronic Communications
    Visiting https://aisupertutor.web.app/ or sending emails to Supertutor constitutes electronic communications. You consent to receive electronic communications and you agree that all agreements, notices, disclosures and other communications that we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communications be in writing.
    
    Your Account
    If you use this site, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password. You may not assign or otherwise transfer your account to any other person or entity. You acknowledge that Supertutor is not responsible for third party access to your account that results from theft or misappropriation of your account. Supertutor and its associates reserve the right to refuse or cancel service, terminate accounts, or remove or edit content in our sole discretion.
    
    Children Under Thirteen
    Supertutor does not knowingly collect, either online or offline, personal information from persons under the age of thirteen. If you are under 18, you may use https://aisupertutor.web.app/ only with permission of a parent or guardian.
    
    Cancellation/Refund Policy
    All purchases are non-refundable. You can cancel your subscription at any time by logging into your account. 
    If you are unsatisfied with our services, please email us at support@aisupertutor.org.
    
    Intellectual Property
All content generated by the Supertutor AI, including but not limited to text, images, and videos, is the property of Supertutor. Users retain ownership of any input data they provide to the service. By using the service, users grant Supertutor a worldwide, royalty-free, and non-exclusive license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content.
Third-Party Services
Supertutor may use third-party APIs and services to power its AI tutor. These services are subject to their own terms and privacy policies, which can be found here: [List third-party services and links to their terms/privacy policies].
Age Requirements
Users must be at least 13 years of age to use the Supertutor service. Users under the age of 18 must have parental permission to use the service.
User-Generated Content
Users are solely responsible for any content they submit to the service. Prohibited content includes but is not limited to: copyrighted material, trademarks, hate speech, explicit content, and content that violates applicable laws. Supertutor reserves the right to remove any user-generated content at its sole discretion.
    
    No Unlawful or Prohibited Use/Intellectual Property
    You are granted a non-exclusive, non-transferable, revocable license to access and use https://aisupertutor.web.app/ strictly in accordance with these terms of use. As a condition of your use of the Site, you warrant to Supertutor that you will not use the Site for any purpose that is unlawful or prohibited by these Terms. You may not use the Site in any manner which could damage, disable, overburden, or impair the Site or interfere with any other party's use and enjoyment of the Site. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Site.
    
    All content included as part of the Service, such as text, graphics, logos, images, as well as the compilation thereof, and any software used on the Site, is the property of Superutor or its suppliers and protected by copyright and other laws that protect intellectual property and proprietary rights. You agree to observe and abide by all copyright and other proprietary notices, legends or other restrictions contained in any such content and will not make any changes thereto.
    
    You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create derivative works, or in any way exploit any of the content, in whole or in part, found on the Site. Superutotr content is not for resale. Your use of the Site does not entitle you to make any unauthorized use of any protected content, and in particular you will not delete or alter any proprietary rights or attribution notices in any content. You will use protected content solely for your personal use, and will make no other use of the content without the express written permission of Supertutor and the copyright owner. You agree that you do not acquire any ownership rights in any protected content. We do not grant you any licenses, express or implied, to the intellectual property of Supertutor or our licensors except as expressly authorized by these Terms.
    
    International Users
    The Service is controlled, operated and administered by Supertutor from our offices within the USA. If you access the Service from a location outside the USA, you are responsible for compliance with all local laws. You agree that you will not use the Supertutor Content accessed through https://aisupertutor.web.app/ in any country or in any manner prohibited by any applicable laws, restrictions or regulations.
    
    Indemnification
    You agree to indemnify, defend and hold harmless Supertutor LLC, its officers, directors, employees, agents and third parties, for any losses, costs, liabilities and expenses (including reasonable attorney's fees) relating to or arising out of your use of or inability to use the Site or services, any user postings made by you, your violation of any terms of this Agreement or your violation of any rights of a third party, or your violation of any applicable laws, rules or regulations. Supertutor reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with Supertutor in asserting any available defenses.
    
    Arbitration
    In the event the parties are not able to resolve any dispute between them arising out of or concerning these Terms and Conditions, or any provisions hereof, whether in contract, tort, or otherwise at law or in equity for damages or any other relief, then such dispute shall be resolved only by final and binding arbitration pursuant to the Federal Arbitration Act, conducted by a single neutral arbitrator and administered by the American Arbitration Association, or a similar arbitration service selected by the parties, in a location mutually agreed upon by the parties. The arbitrator's award shall be final, and judgment may be entered upon it in any court having jurisdiction. In the event that any legal or equitable action, proceeding or arbitration arises out of or concerns these Terms and Conditions, the prevailing party shall be entitled to recover its costs and reasonable attorney's fees. The parties agree to arbitrate all disputes and claims in regards to these Terms and Conditions or any disputes arising as a result of these Terms and Conditions, whether directly or indirectly, including Tort claims that are a result of these Terms and Conditions. The parties agree that the Federal Arbitration Act governs the interpretation and enforcement of this provision. The entire dispute, including the scope and enforceability of this arbitration provision shall be determined by the Arbitrator. This arbitration provision shall survive the termination of these Terms and Conditions.
    
    Class Action Waiver
    Any arbitration under these Terms and Conditions will take place on an individual basis; class arbitrations and class/representative/collective actions are not permitted. THE PARTIES AGREE THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY IN EACH'S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE AND/ OR REPRESENTATIVE PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE ATTORNEY GENERAL ACTION AGAINST THE OTHER. Further, unless both you and Supertutor LLC agree otherwise, the arbitrator may not consolidate more than one person's claims, and may not otherwise preside over any form of a representative or class proceeding.
    
    Liability Disclaimer
    THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. Supertutor AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.
    
    Supertutor AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND. Supertutor AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NONINFRINGEMENT.
    
    TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL Supertutor AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF Supertutor LLC OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.
    
    Termination/Access Restriction
    Supertutor reserves the right, in its sole discretion, to terminate your access to the Site and the related services or any portion thereof at any time, without notice. To the maximum extent permitted by law, this agreement is governed by the laws of the State of Delaware and you hereby consent to the exclusive jurisdiction and venue of courts in Delaware in all disputes arising out of or relating to the use of the Site. Use of the Site is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms, including, without limitation, this section.
    
    You agree that no joint venture, partnership, employment, or agency relationship exists between you and Supertutor as a result of this agreement or use of the Site. Supertutor’s performance of this agreement is subject to existing laws and legal process, and nothing contained in this agreement is in derogation of Supertutor’s right to comply with governmental, court and law enforcement requests or requirements relating to your use of the Site or information provided to or gathered by Supertutor with respect to such use. If any part of this agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the agreement shall continue in effect.
    
    Unless otherwise specified herein, this agreement constitutes the entire agreement between the user and Supertutor with respect to the Site and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between the user and Supertutor with respect to the Site. A printed version of this agreement and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. It is the express wish to the parties that this agreement and all related documents be written in English.
    
    Changes to Terms
    Supertutor reserves the right, in its sole discretion, to change the Terms under which https://aisupertutor.web.app/ is offered. The most current version of the Terms will supersede all previous versions. Supertutor encourages you to periodically review the Terms to stay informed of our updates.
    
    Contact Us
    Supertutor  welcomes your questions or comments regarding the Terms:
    
    Email Address: support@aisupertutor.org
    
    Privacy Policy
    
    Supertutor ("us", "we", or "our") operates the https://aisupertutor.web.app/ website and browser extension (hereinafter referred to as the "Service"). This page informs you of our policies regarding the collection, use and disclosure of personal data when you use our Service and the choices you have associated with that data. We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible from https://aisupertutor.web.app/
    
    Definitions
    Service: Service is the https://aisupertutor.web.app/ website operated by Tutto LLC
    
    Personal Data: Personal Data means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).
    
    Usage Data: Usage Data is data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
    
    Cookies: Cookies are small files stored on your device (computer or mobile device).
    
    Data Controller: Data Controller means the natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal information are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your Personal Data.
    
    Data Processors (or Service Providers): Data Processor (or Service Provider) means any natural or legal person who processes the data on behalf of the Data Controller. We may use the services of various Service Providers in order to process your data more effectively.
    
    Data Subject (or User): Data Subject is any living individual who is using our Service and is the subject of Personal Data.
    
    Information Collection and Use: We collect several different types of information for various purposes to provide and improve our Service to you.
    
    User Data
    We collect the following data from our users:
    
    Personal Data: While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:
    Email address
    
    Cookies and Usage data
    We may also collect information on how the Service is accessed and used ("Usage Data"). This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data. Tracking & Cookies Data: We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.
    
    Website Content: We collect text, images, sounds, videos, or hyperlinks that you submit or upload to our website to provide you with our services.
    We do not sell or transfer user data to third parties, outside of the approved use cases. We do not use or transfer user data for purposes that are unrelated to our item's single purpose. We take data privacy seriously and are committed to safeguarding the confidentiality, integrity, and availability of our users' data.
    We use user data only to provide you with our services, to communicate with you about your account or our services, and to improve the quality of our services. We may also use user data to comply with legal obligations, resolve disputes, or enforce our agreements.
    We implement technical, administrative, and physical security measures to protect user data from unauthorized access, use, or disclosure. We also conduct periodic assessments of our security controls to ensure that we maintain the highest level of data security.
    Cookies: Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyze our Service. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.
    Examples of Cookies we use:
    Session Cookies: We use Session Cookies to operate our Service.
    Preference Cookies: We use Preference Cookies to remember your preferences and various settings.
    Security Cookies: We use Security Cookies for security purposes.
    
    Use of Data
    Supertutor uses the collected data for various purposes: To provide and maintain our Service To notify you about changes to our Service To allow you to participate in interactive features of our Service when you choose to do so To provide customer support To gather analysis or valuable information so that we can improve our Service To monitor the usage of our Service To detect, prevent and address technical issues
    
    Legal Basis for Processing Personal Data under the General Data Protection Regulation (GDPR)
    If you are from the European Economic Area (EEA), Supertutor legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Data we collect and the specific context in which we collect it. Supertutor may process your Personal Data because: We need to perform a contract with you You have given us permission to do so The processing is in our legitimate interests and it is not overridden by your rights For payment processing purposes To comply with the law
    
    Retention of Data
    Superttor will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes and enforce our legal agreements and policies. Supertutor will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer periods.
    
    Disclosure of Data
    Business Transaction
    If Supertutor is involved in a merger, acquisition or asset sale, your Personal Data may be transferred. We will provide notice before your Personal Data is transferred and becomes subject to a different Privacy Policy.
    
    Disclosure for Law Enforcement
    Under certain circumstances, Supertuotr may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).
    
    Legal Requirements
    Supertutor may disclose your Personal Data in the good faith belief that such action is necessary to:
    To comply with a legal obligation
    To protect and defend the rights or property of Supertutor 
    To prevent or investigate possible wrongdoing in connection with the Service
    To protect the personal safety of users of the Service or the public
    To protect against legal liability
    
    Security of Data
    The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
    
    
    Service Providers
    We may employ third party companies and individuals to facilitate our Service ("Service Providers"), provide the Service on our behalf, perform Service-related services or assist us in analysing how our Service is used. These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.
    
    Payments
    We may provide paid products and/or services within the Service. In that case, we use third-party services for payment processing (e.g. payment processors).
    We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, MasterCard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.
    The payment processors we work with are:Stripe Their Privacy Policy can be viewed at https://stripe.com/us/privacy
    
    Links to Other Sites
    Our Service may contain links to other sites that are not operated by us. If you click a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit. We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
    
    Children's Privacy
    Our Service does not address anyone under the age of 18 ("Children"). We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.
    
    Changes to This Privacy Policy
    We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
    
    Contact Us
    If you have any questions about this Privacy Policy, please contact us support@aisupertutor.org 
    
    ]
`;

export default termsOfService;
