import { OAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth, firestore } from './firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';

export async function signInWithApple() {
  const provider = new OAuthProvider('apple.com');
  provider.addScope('email');
  provider.addScope('name');

  try {
    const result = await signInWithPopup(auth, provider);
    const credential = OAuthProvider.credentialFromResult(result);
    const accessToken = credential.accessToken;
    const idToken = credential.idToken;
    const user = result.user;

    // Get the user's name and email from the Apple sign-in result
    const displayName = user.displayName || '';
    const email = user.email || '';

    const userRef = doc(firestore, `users/${user.uid}`);
    const userDoc = await getDoc(userRef);

    let userCredits = 3; // Default credit value

    if (!userDoc.exists()) {
      // User document doesn't exist, create a new one with initial credits and lastResetDate
      await setDoc(userRef, {
        name: displayName,
        email: email,
        subscriptionPlan: "free",
        credits: userCredits,
        lastResetDate: new Date().toDateString(),
      });
    } else {
      // User document already exists, merge the data without overwriting credits
      await setDoc(userRef, {
        name: displayName,
        email: email,
      }, { merge: true });
    }

    // Handle the signed-in user information
    console.log('Signed in with Apple:', user);

    return { user, accessToken, idToken };
  } catch (error) {
    console.error('Error signing in with Apple:', error);
    throw error;
  }
}

// export async function signInWithApple() {
//   const provider = new OAuthProvider('apple.com');
//   provider.addScope('email');
//   provider.addScope('name');

//   try {
//     const result = await signInWithPopup(auth, provider);
//     const credential = OAuthProvider.credentialFromResult(result);
//     const accessToken = credential.accessToken;
//     const idToken = credential.idToken;
//     const user = result.user;

//     // Handle the signed-in user information
//     console.log('Signed in with Apple:', user);

//     return { user, accessToken, idToken };
//   } catch (error) {
//     console.error('Error signing in with Apple:', error);
//     throw error;
//   }
// }


// // AppleSignIn.js
// import { OAuthProvider } from "firebase/auth";
// import { signInWithPopup } from "firebase/auth";
// const provider = new OAuthProvider('apple.com');

// // Optional: Specify additional OAuth 2.0 scopes
// provider.addScope('email');
// provider.addScope('name');

// // Optional: Set the locale for the Apple sign-in screen
// provider.setCustomParameters({
//   locale: 'fr', // Example: French locale
// });

// export const signInWithApple = (auth) => {
//   return signInWithPopup(auth, provider)
//     .then((result) => {
//       // Handle successful sign-in
//       const user = result.user;
//       const credential = OAuthProvider.credentialFromResult(result);
//       const accessToken = credential.accessToken;
//       const idToken = credential.idToken;
//       // ...
//     })
//     .catch((error) => {
//       // Handle sign-in errors
//       const errorCode = error.code;
//       const errorMessage = error.message;
//       const email = error.customData.email;
//       const credential = OAuthProvider.credentialFromError(error);
//       // ...
//     });
// };