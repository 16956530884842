import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const PageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  font-family: 'Poppins', sans-serif;
  color: #333;
`;

const Header = styled.h1`
  font-size: 3em;
  color: #045AC6;
  text-align: center;
  margin-bottom: 40px;
`;

const Section = styled.section`
  margin-bottom: 40px;
`;

const SubHeader = styled.h2`
  font-size: 2em;
  color: #0077BE;
  margin-bottom: 20px;
`;

const Paragraph = styled.p`
  font-size: 1.1em;
  line-height: 1.6;
  margin-bottom: 20px;
`;

const GameGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin-bottom: 40px;
`;

const GameCard = styled.div`
  background-color: #f8f8f8;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const GameIcon = styled.div`
  font-size: 3em;
  margin-bottom: 10px;
  color: #045AC6;
`;

const GameTitle = styled.h3`
  font-size: 1.3em;
  color: #0077BE;
  margin-bottom: 10px;
`;

const GameDescription = styled.p`
  font-size: 1em;
  line-height: 1.5;
`;

const HomeButton = styled(Link)`
  display: inline-block;
  margin: 20px 0;
  padding: 10px 20px;
  background-color: #045AC6;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #034494;
  }
`;

const MultiplayerGames = () => {
  const gamesList = [
    {
      icon: '🧠',
      title: 'Trivia Challenges',
      description: 'Test your knowledge across various subjects in fast-paced trivia battles.'
    },
    {
      icon: '🔢',
      title: 'Math Races',
      description: 'Compete with friends to solve math problems quickly and accurately.'
    },
    {
      icon: '🌍',
      title: 'Language Learning Battles',
      description: 'Improve your language skills through fun, competitive word games.'
    },
    {
      icon: '🧪',
      title: 'Science Quests',
      description: 'Embark on exciting scientific adventures and solve puzzles with your peers.'
    },
    {
      icon: '📚',
      title: 'Literature Legends',
      description: 'Dive into the world of books and challenge your literary knowledge.'
    },
    {
      icon: '🎨',
      title: 'Art & History Timeline',
      description: 'Race to place historical events and artworks in the correct chronological order.'
    }
  ];

  return (
    <PageContainer>
      <Header>Educational Multiplayer Games</Header>
      <Section>
        <Paragraph>
          Engage in fun, educational multiplayer games with SUPERTUTOR AI. Learn while competing with friends and peers from around the world!
        </Paragraph>
      </Section>
      
      <Section>
        <SubHeader>Our Game Offerings</SubHeader>
        <GameGrid>
          {gamesList.map((game, index) => (
            <GameCard key={index}>
              <GameIcon>{game.icon}</GameIcon>
              <GameTitle>{game.title}</GameTitle>
              <GameDescription>{game.description}</GameDescription>
            </GameCard>
          ))}
        </GameGrid>
      </Section>
      
      <Section>
        <SubHeader>Benefits of Multiplayer Learning</SubHeader>
        <Paragraph>
          Our multiplayer games offer more than just fun – they're designed to enhance your learning experience:
        </Paragraph>
        <ul>
          <li>Boost engagement and motivation through friendly competition</li>
          <li>Improve retention through active recall and spaced repetition</li>
          <li>Develop critical thinking and quick decision-making skills</li>
          <li>Foster collaboration and communication with peers</li>
          <li>Provide a fun, stress-free environment for learning</li>
        </ul>
      </Section>
      
      <HomeButton to="/">Return to Home</HomeButton>
    </PageContainer>
  );
};

export default MultiplayerGames;