import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { doc, updateDoc, getDoc, setDoc } from 'firebase/firestore';
import { firestore, auth } from './firebase';
import { onAuthStateChanged, getRedirectResult, signInWithCustomToken } from 'firebase/auth';

const proPlanPriceId = 'price_1OtAtyJcTMg4yEiliPyYtJgf';
const beyondProPlanPriceId = 'price_1OtEfVJcTMg4yEil4MbigM91';
const beyondUnlimitedPlanPriceId = 'price_1PcHyOJcTMg4yEilQk6WsUCn';

const SuccessPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const updateSubscription = useCallback(async (user) => {
    try {
      const searchParams = new URLSearchParams(location.search);
      const priceId = searchParams.get('plan');
      const stripeCustomerId = searchParams.get('stripeCustomerId');

      console.log('Updating subscription for user:', user.uid);
      console.log('Price ID:', priceId);
      console.log('Stripe Customer ID:', stripeCustomerId);

      if (!priceId) {
        throw new Error('Missing price ID');
      }

      let subscriptionPlan;
      let credits;
      switch (priceId) {
        case proPlanPriceId:
          subscriptionPlan = 'pro';
          credits = 40;
          break;
        case beyondProPlanPriceId:
        case beyondUnlimitedPlanPriceId:
          subscriptionPlan = 'beyond_pro';
          credits = Infinity;
          break;
        default:
          throw new Error('Invalid plan ID');
      }

      const userRef = doc(firestore, 'users', user.uid);
      const userDoc = await getDoc(userRef);

      const updateData = {
        subscriptionPlan,
        credits,
        updatedAt: new Date()
      };

      if (stripeCustomerId) {
        updateData.stripeCustomerId = stripeCustomerId;
      }

      if (!userDoc.exists()) {
        console.log('User document does not exist. Creating new document.');
        await setDoc(userRef, {
          email: user.email || '',
          name: user.displayName || '',
          ...updateData,
          createdAt: new Date()
        });
      } else {
        console.log('Updating existing user document.');
        await updateDoc(userRef, updateData);
      }

      console.log('Subscription updated successfully');
      localStorage.setItem('subscriptionUpdated', 'true');

      setTimeout(() => {
        navigate('/askquestion', { replace: true });
      }, 2000);
    } catch (error) {
      console.error('Error updating subscription:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [location.search, navigate]);

  useEffect(() => {
    const handleAuth = async () => {
      try {
        // First, check for redirect result
        const result = await getRedirectResult(auth);
        if (result?.user) {
          await updateSubscription(result.user);
          return;
        }

        // If no redirect result, check current auth state
        const user = auth.currentUser;
        if (user) {
          await updateSubscription(user);
          return;
        }

        // If still no user, check for a stored token
        const storedToken = localStorage.getItem('authToken');
        if (storedToken) {
          try {
            const userCredential = await signInWithCustomToken(auth, storedToken);
            await updateSubscription(userCredential.user);
            return;
          } catch (tokenError) {
            console.error('Error signing in with stored token:', tokenError);
          }
        }

        // If all else fails, set up a listener for auth state changes
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
          if (user) {
            unsubscribe();
            await updateSubscription(user);
          } else {
            console.log('No authenticated user');
            setError('User not authenticated');
            setLoading(false);
            setTimeout(() => navigate('/', { replace: true }), 2000);
          }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
      } catch (error) {
        console.error('Error in handleAuth:', error);
        setError(error.message);
        setLoading(false);
      }
    };

    handleAuth();
  }, [navigate, updateSubscription]);

  if (loading) {
    return <div>Updating subscription...</div>;
  }

  if (error) {
    return <div>Error: {error}. Redirecting to home page...</div>;
  }

  return <div>Subscription updated successfully! Redirecting...</div>;
};

export default SuccessPage;
// import React, { useEffect, useState, useCallback } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
// import { doc, updateDoc, getDoc, setDoc } from 'firebase/firestore';
// import { firestore, auth } from './firebase';
// import { onAuthStateChanged } from 'firebase/auth';

// const proPlanPriceId = 'price_1OtAtyJcTMg4yEiliPyYtJgf';
// const beyondProPlanPriceId = 'price_1OtEfVJcTMg4yEil4MbigM91';
// const beyondUnlimitedPlanPriceId = 'price_1PcHyOJcTMg4yEilQk6WsUCn';

// const SuccessPage = () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   const updateSubscription = useCallback(async (user) => {
//     try {
//       const searchParams = new URLSearchParams(location.search);
//       const priceId = searchParams.get('plan');
//       const stripeCustomerId = searchParams.get('stripeCustomerId');

//       console.log('Updating subscription for user:', user.uid);
//       console.log('Price ID:', priceId);
//       console.log('Stripe Customer ID:', stripeCustomerId);

//       if (!priceId) {
//         throw new Error('Missing price ID');
//       }

//       let subscriptionPlan;
//       let credits;
//       switch (priceId) {
//         case proPlanPriceId:
//           subscriptionPlan = 'pro';
//           credits = 40;
//           break;
//         case beyondProPlanPriceId:
//         case beyondUnlimitedPlanPriceId:
//           subscriptionPlan = 'beyond_pro';
//           credits = Infinity;
//           break;
//         default:
//           throw new Error('Invalid plan ID');
//       }

//       const userRef = doc(firestore, 'users', user.uid);
//       const userDoc = await getDoc(userRef);

//       const updateData = {
//         subscriptionPlan,
//         credits,
//         updatedAt: new Date()
//       };

//       if (stripeCustomerId) {
//         updateData.stripeCustomerId = stripeCustomerId;
//       }

//       if (!userDoc.exists()) {
//         console.log('User document does not exist. Creating new document.');
//         await setDoc(userRef, {
//           email: user.email || '',
//           name: user.displayName || '',
//           ...updateData,
//           createdAt: new Date()
//         });
//       } else {
//         console.log('Updating existing user document.');
//         await updateDoc(userRef, updateData);
//       }

//       console.log('Subscription updated successfully');
//       localStorage.setItem('subscriptionUpdated', 'true');

//       // Delay navigation to ensure Firestore update is complete
//       setTimeout(() => {
//         navigate('/askquestion', { replace: true });
//       }, 2000);
//     } catch (error) {
//       console.error('Error updating subscription:', error);
//       setError(error.message);
//     } finally {
//       setLoading(false);
//     }
//   }, [location.search, navigate]);

//   useEffect(() => {
//     const checkAuthAndUpdateSubscription = async () => {
//       try {
//         const user = auth.currentUser;
//         if (user) {
//           await updateSubscription(user);
//         } else {
//           const unsubscribe = onAuthStateChanged(auth, async (user) => {
//             if (user) {
//               unsubscribe(); // Unsubscribe immediately after getting the user
//               await updateSubscription(user);
//             } else {
//               console.log('No authenticated user');
//               setError('User not authenticated');
//               setLoading(false);
//               // Redirect to home page after a short delay
//               setTimeout(() => navigate('/', { replace: true }), 2000);
//             }
//           });
//         }
//       } catch (error) {
//         console.error('Error in checkAuthAndUpdateSubscription:', error);
//         setError(error.message);
//         setLoading(false);
//       }
//     };

//     checkAuthAndUpdateSubscription();
//   }, [updateSubscription, navigate]);

//   if (loading) {
//     return <div>Updating subscription...</div>;
//   }

//   if (error) {
//     return <div>Error: {error}. Redirecting to home page...</div>;
//   }

//   return <div>Subscription updated successfully! Redirecting...</div>;
// };

// export default SuccessPage;

// import React, { useEffect, useState } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
// import { doc, updateDoc, getDoc } from 'firebase/firestore';
// import { firestore } from './firebase';

// const proPlanPriceId = 'price_1OtAtyJcTMg4yEiliPyYtJgf';
// const beyondProPlanPriceId = 'price_1OtEfVJcTMg4yEil4MbigM91';
// const beyondUnlimitedPlanPriceId = 'price_1PcHyOJcTMg4yEilQk6WsUCn';

// // const beyondUnlimitedPlanPriceId = 'price_1P344YJcTMg4yEilyHYQh0Bl';

// const SuccessPage = ({ user }) => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const updateSubscription = async () => {
//       try {
//         if (!user) {
//           throw new Error('User not authenticated');
//         }

//         const searchParams = new URLSearchParams(location.search);
//         const priceId = searchParams.get('plan');
//         const stripeCustomerId = searchParams.get('stripeCustomerId');

//         console.log('Updating subscription for user:', user.uid);
//         console.log('Price ID:', priceId);
//         console.log('Stripe Customer ID:', stripeCustomerId);

//         let subscriptionPlan;
//         let credits;
//         switch (priceId) {
//           case proPlanPriceId:
//             subscriptionPlan = 'pro';
//             credits = 40;
//             break;
//           case beyondProPlanPriceId:
//           case beyondUnlimitedPlanPriceId:
//             subscriptionPlan = 'beyond_pro';
//             credits = Infinity;
//             break;
//           default:
//             throw new Error('Invalid plan ID');
//         }

//         const userRef = doc(firestore, 'users', user.uid);
//         const userDoc = await getDoc(userRef);

//         if (userDoc.exists()) {
//           const updateData = {
//             subscriptionPlan,
//             credits,
//           };

//           if (stripeCustomerId) {
//             updateData.stripeCustomerId = stripeCustomerId;
//           }

//           await updateDoc(userRef, updateData);
//           console.log('Subscription updated successfully');
//           localStorage.setItem('subscriptionUpdated', 'true');

//           // Redirect to home page
//           navigate('/askquestion', { replace: true });
//         } else {
//           throw new Error('User document not found');
//         }
//       } catch (error) {
//         console.error('Error updating subscription:', error);
//         setError(error.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     updateSubscription();
//   }, [user, location.search, navigate]);

//   if (loading) {
//     return <div>Updating subscription...</div>;
//   }

//   if (error) {
//     return <div>Error: {error}</div>;
//   }

//   return null;
// };

// export default SuccessPage;

// import React, { useEffect, useState } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
// import { doc, updateDoc, getDoc } from 'firebase/firestore';
// import { firestore, auth } from './firebase';
// import { onAuthStateChanged } from 'firebase/auth';

// const proPlanPriceId = 'price_1OtAtyJcTMg4yEiliPyYtJgf';
// const beyondProPlanPriceId = 'price_1OtEfVJcTMg4yEil4MbigM91';
// const beyondUnlimitedPlanPriceId = 'price_1P344YJcTMg4yEilyHYQh0Bl';

// const SuccessPage = () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const updateSubscription = async (user) => {
//       try {
//         const searchParams = new URLSearchParams(location.search);
//         const priceId = searchParams.get('plan');
//         const stripeCustomerId = searchParams.get('stripeCustomerId');
//         const urlUserId = searchParams.get('userId');

//         console.log('Updating subscription for user:', user.uid);
//         console.log('Price ID:', priceId);
//         console.log('Stripe Customer ID:', stripeCustomerId);
//         console.log('URL User ID:', urlUserId);

//         // Check if the URL contains a userId and if it matches the authenticated user
//         if (urlUserId && urlUserId !== user.uid) {
//           throw new Error('User ID mismatch');
//         }

//         let subscriptionPlan;
//         let credits;
//         switch (priceId) {
//           case proPlanPriceId:
//             subscriptionPlan = 'pro';
//             credits = 40;
//             break;
//           case beyondProPlanPriceId:
//           case beyondUnlimitedPlanPriceId:
//             subscriptionPlan = 'beyond_pro';
//             credits = Infinity;
//             break;
//           default:
//             throw new Error('Invalid plan ID');
//         }

//         const userRef = doc(firestore, 'users', user.uid);
//         const userDoc = await getDoc(userRef);

//         if (userDoc.exists()) {
//           const updateData = {
//             subscriptionPlan,
//             credits,
//           };

//           if (stripeCustomerId) {
//             updateData.stripeCustomerId = stripeCustomerId;
//           }

//           await updateDoc(userRef, updateData);
//           console.log('Subscription updated successfully');
//           localStorage.setItem('subscriptionUpdated', 'true');

//           // Redirect to home page
//           navigate('/', { replace: true });
//         } else {
//           throw new Error('User document not found');
//         }
//       } catch (error) {
//         console.error('Error updating subscription:', error);
//         setError(error.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     const checkAuthAndUpdateSubscription = () => {
//       const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
//         if (currentUser) {
//           console.log('User authenticated:', currentUser.uid);
//           updateSubscription(currentUser);
//         } else {
//           console.error('User not authenticated');
//           setError('User not authenticated');
//           setLoading(false);
//         }
//       });

//       return unsubscribe;
//     };

//     const unsubscribe = checkAuthAndUpdateSubscription();

//     return () => {
//       if (unsubscribe) unsubscribe();
//     };
//   }, [location.search, navigate]);

//   if (loading) {
//     return <div>Updating subscription...</div>;
//   }

//   if (error) {
//     return <div>Error: {error}</div>;
//   }

//   return null;
// };

// export default SuccessPage;

// import React, { useEffect, useState, useCallback } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
// import { doc, updateDoc, getDoc } from 'firebase/firestore';
// import { firestore, auth } from './firebase';
// import { onAuthStateChanged } from 'firebase/auth';

// const proPlanPriceId = 'price_1OtAtyJcTMg4yEiliPyYtJgf';
// const beyondProPlanPriceId = 'price_1OtEfVJcTMg4yEil4MbigM91';
// const beyondUnlimitedPlanPriceId = 'price_1P344YJcTMg4yEilyHYQh0Bl';

// const SuccessPage = () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   const updateSubscription = useCallback(async (user) => {
//     try {
//       const searchParams = new URLSearchParams(location.search);
//       const priceId = searchParams.get('plan');
//       const stripeCustomerId = searchParams.get('stripeCustomerId');

//       let subscriptionPlan;
//       let credits;
//       switch (priceId) {
//         case proPlanPriceId:
//           subscriptionPlan = 'pro';
//           credits = 40;
//           break;
//         case beyondProPlanPriceId:
//         case beyondUnlimitedPlanPriceId:
//           subscriptionPlan = 'beyond_pro';
//           credits = Infinity;
//           break;
//         default:
//           throw new Error('Invalid plan ID');
//       }

//       const userRef = doc(firestore, 'users', user.uid);
//       const userDoc = await getDoc(userRef);

//       if (userDoc.exists()) {
//         const updateData = {
//           subscriptionPlan,
//           credits,
//         };

//         if (stripeCustomerId) {
//           updateData.stripeCustomerId = stripeCustomerId;
//         }

//         await updateDoc(userRef, updateData);
//         console.log('Subscription updated successfully');
//         localStorage.setItem('subscriptionUpdated', 'true');

//         // Redirect to home page
//         navigate('/', { replace: true });
//       } else {
//         throw new Error('User document not found');
//       }
//     } catch (error) {
//       console.error('Error updating subscription:', error);
//       setError(error.message);
//     } finally {
//       setLoading(false);
//     }
//   }, [location.search, navigate]);

//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
//       if (currentUser) {
//         updateSubscription(currentUser);
//       } else {
//         console.error('User not authenticated');
//         setError('User not authenticated');
//         setLoading(false);
//       }
//     });

//     return () => unsubscribe();
//   }, [updateSubscription]);

//   if (loading) {
//     return <div>Updating subscription...</div>;
//   }

//   if (error) {
//     return <div>Error: {error}</div>;
//   }

//   return null;
// };

// export default SuccessPage;

// import React, { useEffect, useState } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
// import { doc, updateDoc, getDoc } from 'firebase/firestore';
// import { firestore, auth } from './firebase';
// import { onAuthStateChanged } from 'firebase/auth';

// const proPlanPriceId = 'price_1OtAtyJcTMg4yEiliPyYtJgf';
// const beyondProPlanPriceId = 'price_1OtEfVJcTMg4yEil4MbigM91';
// const beyondUnlimitedPlanPriceId = 'price_1P344YJcTMg4yEilyHYQh0Bl';

// const SuccessPage = () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const updateSubscription = async (user) => {
//       if (!user) {
//         setError('User not authenticated');
//         setLoading(false);
//         return;
//       }

//       try {
//         const searchParams = new URLSearchParams(location.search);
//         const priceId = searchParams.get('plan');
//         const stripeCustomerId = searchParams.get('stripeCustomerId');

//         let subscriptionPlan;
//         let credits;
//         switch (priceId) {
//           case proPlanPriceId:
//             subscriptionPlan = 'pro';
//             credits = 40;
//             break;
//           case beyondProPlanPriceId:
//           case beyondUnlimitedPlanPriceId:
//             subscriptionPlan = 'beyond_pro';
//             credits = Infinity;
//             break;
//           default:
//             throw new Error('Invalid plan ID');
//         }

//         const userRef = doc(firestore, 'users', user.uid);
//         const userDoc = await getDoc(userRef);

//         if (userDoc.exists()) {
//           const updateData = {
//             subscriptionPlan,
//             credits,
//           };

//           if (stripeCustomerId) {
//             updateData.stripeCustomerId = stripeCustomerId;
//           }

//           await updateDoc(userRef, updateData);
//           console.log('Subscription updated successfully');
//           localStorage.setItem('subscriptionUpdated', 'true');
//         } else {
//           throw new Error('User document not found');
//         }

//         // Redirect to home page
//         navigate('/', { replace: true });
//       } catch (error) {
//         console.error('Error updating subscription:', error);
//         setError(error.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     const unsubscribe = onAuthStateChanged(auth, (user) => {
//       if (user) {
//         updateSubscription(user);
//       } else {
//         setLoading(false);
//         setError('User not authenticated');
//       }
//     });

//     return () => unsubscribe();
//   }, [navigate, location.search]);

//   if (loading) {
//     return <div>Updating subscription...</div>;
//   }

//   if (error) {
//     return <div>Error: {error}</div>;
//   }

//   return null;
// };

// export default SuccessPage;

// import React, { useEffect, useCallback, useState } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { doc, updateDoc } from 'firebase/firestore';
// import { firestore, auth } from './firebase';
// import { onAuthStateChanged } from 'firebase/auth';

// const proPlanPriceId = 'price_1OtAtyJcTMg4yEiliPyYtJgf';
// const beyondProPlanPriceId = 'price_1OtEfVJcTMg4yEil4MbigM91';
// const beyondUnlimitedPlanPriceId = 'price_1P344YJcTMg4yEilyHYQh0Bl';

// const SuccessPage = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const searchParams = new URLSearchParams(location.search);
//   const priceId = searchParams.get('plan');
//   const stripeCustomerId = searchParams.get('stripeCustomerId') || null;
//   const [user, setUser] = useState(null);

//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
//       setUser(currentUser);
//     });

//     return () => unsubscribe();
//   }, []);

//   const handleAccept = useCallback(async () => {
//     if (!user) {
//       console.log('Waiting for user to be authenticated...');
//       return;
//     }

//     try {
//       let subscriptionPlan;
//       let credits;
//       switch (priceId) {
//         case proPlanPriceId:
//           subscriptionPlan = 'pro';
//           credits = 40;
//           break;
//         case beyondProPlanPriceId:
//         case beyondUnlimitedPlanPriceId:
//           subscriptionPlan = 'beyond_pro';
//           credits = Infinity;
//           break;
//         default:
//           alert('Invalid plan ID');
//           navigate('/');
//           return;
//       }
  
//       const userRef = doc(firestore, 'users', user.uid);
//       const updateData = { subscriptionPlan, credits, stripeCustomerId };
//       await updateDoc(userRef, updateData);
  
//       alert('Subscription plan updated successfully');
//       navigate('/');
//     } catch (error) {
//       console.error('Error updating user subscription:', error);
//       alert('An error occurred while updating your subscription. Please try again or contact support.');
//     }
//   }, [user, priceId, stripeCustomerId, navigate]);

//   useEffect(() => {
//     if (user) {
//       handleAccept();
//     }
//   }, [user, handleAccept]);

//   if (!user) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div style={{ textAlign: 'center', marginTop: '50px' }}>
//       <h2>Subscription Success</h2>
//       <p>
//         You have selected the {priceId === proPlanPriceId ? 'Pro' : priceId === beyondProPlanPriceId ? 'Beyond Pro' : 'Beyond Unlimited'} Plan.
//       </p>
//       <p>Your subscription plan will be updated shortly.</p>
//     </div>
//   );
// };

// export default SuccessPage;


// const SuccessPage = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const searchParams = new URLSearchParams(location.search);
//   const priceId = searchParams.get('plan');
//   const stripeCustomerId = searchParams.get('stripeCustomerId') || null;

//   const handleAccept = useCallback(async () => {
//     try {
//       const userId = auth.currentUser?.uid;
//       if (!userId) {
//         alert('No user signed in.');
//         navigate('/');
//         return;
//       }
  
//       let subscriptionPlan;
//       let credits;
//       switch (priceId) {
//         case proPlanPriceId:
//           subscriptionPlan = 'pro';
//           credits = 40;
//           break;
//         case beyondProPlanPriceId:
//         case beyondUnlimitedPlanPriceId:
//           subscriptionPlan = 'beyond_pro';
//           credits = Infinity;
//           break;
//         default:
//           alert('Invalid plan ID');
//           navigate('/');
//           return;
//       }
  
//       const userRef = doc(firestore, 'users', userId);
//       const updateData = { subscriptionPlan, credits, stripeCustomerId };
//       await updateDoc(userRef, updateData);
  
//       alert('Subscription plan updated successfully');
//       navigate('/');
//     } catch (error) {
//       console.error('Error updating user subscription:', error);
//       alert('An error occurred while updating your subscription. Please try again or contact support.');
//     }
//   }, [priceId, stripeCustomerId, navigate]);

//   useEffect(() => {
//     handleAccept();
//   }, [handleAccept]);

//   return (
//     <div style={{ textAlign: 'center', marginTop: '50px' }}>
//       <h2>Subscription Success</h2>
//       <p>
//         You have selected the {priceId === proPlanPriceId ? 'Pro' : priceId === beyondProPlanPriceId ? 'Beyond Pro' : 'Beyond Unlimited'} Plan.
//       </p>
//       <p>Your subscription plan will be updated shortly.</p>
//     </div>
//   );
// };



// import React, { useEffect, useCallback } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { doc, updateDoc } from 'firebase/firestore';
// import { firestore, auth } from './firebase';

// const proPlanPriceId = 'price_1OtAtyJcTMg4yEiliPyYtJgf';
// const beyondProPlanPriceId = 'price_1OtEfVJcTMg4yEil4MbigM91';
// const beyondUnlimitedPlanPriceId = 'price_your_beyond_unlimited_price_id';

// const SuccessPage = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const searchParams = new URLSearchParams(location.search);
//   const priceId = searchParams.get('plan');
//   const stripeCustomerId = searchParams.get('stripeCustomerId') || null;

//   const handleAccept = useCallback(async () => {
//     try {
//       const userId = auth.currentUser?.uid;
//       if (!userId) {
//         alert('No user signed in.');
//         navigate('/');
//         return;
//       }

//       let subscriptionPlan;
//       let credits;
      
//       switch (priceId) {
//         case proPlanPriceId:
//           subscriptionPlan = 'pro';
//           credits = 40;
//           break;
//         case beyondProPlanPriceId:
//           subscriptionPlan = 'beyond_pro';
//           credits = Infinity;
//           break;
//         case beyondUnlimitedPlanPriceId:
//           subscriptionPlan = 'beyondunlimited';
//           credits = Infinity;
//           break;
//         default:
//           alert('Invalid plan ID');
//           navigate('/askquestion');
//           return;
//       }
      
//       const userRef = doc(firestore, 'users', userId);
//       const updateData = { subscriptionPlan, credits, stripeCustomerId };

//       await updateDoc(userRef, updateData);
//       alert('Subscription plan updated successfully');
//       navigate('/');
//     } catch (error) {
//       console.error('Error updating user subscription:', error);
//       alert('An error occurred while updating your subscription. Please try again or contact support.');
//     }
//   }, [priceId, stripeCustomerId, navigate]);

//   useEffect(() => {
//     handleAccept();
//   }, [handleAccept]);

//   return (
//     <div style={{ textAlign: 'center', marginTop: '50px' }}>
//       <h2>Subscription Success</h2>
//       <p>
//         You have selected the {priceId === proPlanPriceId ? 'Pro' : priceId === beyondProPlanPriceId ? 'Beyond Pro' : 'Beyond Unlimited'} Plan.
//       </p>
//       <p>Please confirm your subscription to update your account.</p>
//       <button onClick={handleAccept} style={{ cursor: 'pointer', padding: '10px 20px', margin: '10px' }}>
//         Accept {priceId === proPlanPriceId ? 'Pro' : priceId === beyondProPlanPriceId ? 'Beyond Pro' : 'Beyond Unlimited'} Upgrade
//       </button>
//       <button onClick={() => navigate('/askquestion')} style={{ cursor: 'pointer', padding: '10px 20px', margin: '10px' }}>
//         Skip for Now
//       </button>
//     </div>
//   );
// };

// export default SuccessPage;


// import React, { useEffect, useCallback } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { doc, updateDoc } from 'firebase/firestore';
// import { firestore, auth } from './firebase';

// const proPlanPriceId = 'price_1OtAtyJcTMg4yEiliPyYtJgf';
// const beyondProPlanPriceId = 'price_1OtEfVJcTMg4yEil4MbigM91';
// const beyondUnlimitedPlanPriceId = 'price_your_beyond_unlimited_price_id';

// const SuccessPage = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const searchParams = new URLSearchParams(location.search);
//   const priceId = searchParams.get('plan');
//   const stripeCustomerId = searchParams.get('stripeCustomerId') || null;

//   const handleAccept = useCallback(async () => {
//     try {
//       const userId = auth.currentUser?.uid;
//       if (!userId) {
//         alert('No user signed in.');
//         navigate('/');
//         return;
//       }

//       let subscriptionPlan;
//       let credits;

//       switch (priceId) {
//         case proPlanPriceId:
//           subscriptionPlan = 'pro';
//           credits = 40;
//           break;
//         case beyondProPlanPriceId:
//           subscriptionPlan = 'beyond_pro';
//           credits = Infinity;
//           break;
//         case beyondUnlimitedPlanPriceId:
//           subscriptionPlan = 'beyondunlimited';
//           credits = Infinity;
//           break;
//         default:
//           alert('Invalid plan ID');
//           navigate('/askquestion');
//           return;
//       }

//       const userRef = doc(firestore, 'users', userId);
//       const updateData = {
//         subscriptionPlan: subscriptionPlan,
//         credits: credits,
//       };

//       if (stripeCustomerId) {
//         updateData.stripeCustomerId = stripeCustomerId;
//       }

//       await updateDoc(userRef, updateData);

//       alert('Subscription plan updated successfully');
//       navigate('/');
//     } catch (error) {
//       console.error('Error updating user subscription:', error);
//       alert('An error occurred while updating your subscription. Please try again or contact support.');
//     }
//   }, [priceId, stripeCustomerId, navigate]);

//   useEffect(() => {
//     handleAccept();
//   }, [handleAccept]);

//   return (
//     <div style={{ textAlign: 'center', marginTop: '50px' }}>
//       <h2>Subscription Success</h2>
//       <p>
//         You have selected the {priceId === proPlanPriceId ? 'Pro' : priceId === beyondProPlanPriceId ? 'Beyond Pro' : 'Beyond Unlimited'} Plan.
//       </p>
//       <p>Please confirm your subscription to update your account.</p>
//       <button onClick={handleAccept} style={{ cursor: 'pointer', padding: '10px 20px', margin: '10px' }}>
//         Accept {priceId === proPlanPriceId ? 'Pro' : priceId === beyondProPlanPriceId ? 'Beyond Pro' : 'Beyond Unlimited'} Upgrade
//       </button>
//       <button onClick={() => navigate('/askquestion')} style={{ cursor: 'pointer', padding: '10px 20px', margin: '10px' }}>
//         Skip for Now
//       </button>
//     </div>
//   );
// };

// export default SuccessPage;




// import React, { useEffect, useCallback } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { doc, updateDoc } from 'firebase/firestore';
// import { firestore, auth } from './firebase';

// const proPlanPriceId = 'price_1OtAtyJcTMg4yEiliPyYtJgf';
// const beyondProPlanPriceId = 'price_1OtEfVJcTMg4yEil4MbigM91';
// const beyondUnlimitedPlanPriceId = 'price_your_beyond_unlimited_price_id';

// const SuccessPage = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const searchParams = new URLSearchParams(location.search);
//   const priceId = searchParams.get('plan');
//   const stripeCustomerId = searchParams.get('stripeCustomerId') || null; // Keep default as null if not present

//   const handleAccept = useCallback(async () => {
//     try {
//       const userId = auth.currentUser?.uid;
//       if (!userId) {
//         alert('No user signed in.');
//         navigate('/');
//         return;
//       }

//       let subscriptionPlan;
//       let credits;

//       switch (priceId) {
//         case proPlanPriceId:
//           subscriptionPlan = 'pro';
//           credits = 40;
//           break;
//         case beyondProPlanPriceId:
//           subscriptionPlan = 'beyond_pro';
//           credits = Infinity;
//           break;
//         case beyondUnlimitedPlanPriceId:
//           subscriptionPlan = 'beyondunlimited';
//           credits = Infinity;
//           break;
//         default:
//           alert('Invalid plan ID');
//           navigate('/askquestion');
//           return;
//       }

//       const userRef = doc(firestore, 'users', userId);
//       const updateData = {
//         subscriptionPlan: subscriptionPlan,
//         credits: credits,
//       };
      
//       if (stripeCustomerId) {
//         updateData.stripeCustomerId = stripeCustomerId;
//       }

//       await updateDoc(userRef, updateData);

//       alert('Subscription plan updated successfully');
//       navigate('/');
//     } catch (error) {
//       console.error('Error updating user subscription:', error);
//       alert('An error occurred while updating your subscription. Please try again or contact support.');
//     }
//   }, [priceId, stripeCustomerId, navigate]);

//   useEffect(() => {
//     handleAccept();
//   }, [handleAccept]); 

//   return (
//     <div style={{ textAlign: 'center', marginTop: '50px' }}>
//       <h2>Subscription Success</h2>
//       <p>
//         You have selected the {priceId === proPlanPriceId ? 'Pro' : priceId === beyondProPlanPriceId ? 'Beyond Pro' : 'Beyond Unlimited'} Plan.
//       </p>
//       <p>Please confirm your subscription to update your account.</p>
//       <button onClick={handleAccept} style={{ cursor: 'pointer', padding: '10px 20px', margin: '10px' }}>
//         Accept {priceId === proPlanPriceId ? 'Pro' : priceId === beyondProPlanPriceId ? 'Beyond Pro' : 'Beyond Unlimited'} Upgrade
//       </button>
//       <button onClick={() => navigate('/askquestion')} style={{ cursor: 'pointer', padding: '10px 20px', margin: '10px' }}>
//         Skip for Now
//       </button>
//     </div>
//   );
// };

// export default SuccessPage;


// import React, { useEffect, useCallback } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { doc, updateDoc } from 'firebase/firestore';
// import { firestore, auth } from './firebase';

// const proPlanPriceId = 'price_1OtAtyJcTMg4yEiliPyYtJgf';
// const beyondProPlanPriceId = 'price_1OtEfVJcTMg4yEil4MbigM91';
// const beyondUnlimitedPlanPriceId = 'price_your_beyond_unlimited_price_id';

// const SuccessPage = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const searchParams = new URLSearchParams(location.search);
//   const priceId = searchParams.get('plan');

//   // const handleAccept = useCallback(async () => {
//   //   try {
//   //     const userId = auth.currentUser?.uid;
//   //     if (!userId) {
//   //       alert('No user signed in.');
//   //       navigate('/');  
//   //       return;
//   //     }

//   //     let subscriptionPlan;
//   //     let credits;

//   //     switch (priceId) {
//   //       case proPlanPriceId:
//   //         subscriptionPlan = 'pro';
//   //         credits = 40;
//   //         break;
//   //       case beyondProPlanPriceId:
//   //         subscriptionPlan = 'beyond_pro';
//   //         credits = Infinity;
//   //         break;
//   //         case beyondUnlimitedPlanPriceId:
//   // subscriptionPlan = 'beyondunlimited';
//   // credits = Infinity;
//   // break;
//   //       default:
//   //         alert('Invalid plan ID');
//   //         navigate('/askquestion'); // Navigate to home or an error page
//   //         return;
//   //     }

//   //     const userRef = doc(firestore, 'users', userId);
//   //     await updateDoc(userRef, {
//   //       subscriptionPlan: subscriptionPlan,
//   //       credits: credits,
//   //     });

//   //     alert('Subscription plan updated successfully');
//   //     navigate('/'); 
//   //   } catch (error) {
//   //     console.error('Error updating user subscription:', error);
//   //     alert('An error occurred while updating your subscription. Please try again or contact support.');
//   //   }
//   // }, [priceId, navigate]); // Dependencies for useCallback

//   const handleAccept = useCallback(async () => {
//     try {
//       const userId = auth.currentUser?.uid;
//       if (!userId) {
//         alert('No user signed in.');
//         navigate('/');
//         return;
//       }
  
//       // Get the Stripe customer ID from the query parameters
//       const stripeCustomerId = searchParams.get('stripeCustomerId');
  
//       let subscriptionPlan;
//       let credits;
  
//       switch (priceId) {
//         case proPlanPriceId:
//           subscriptionPlan = 'pro';
//           credits = 40;
//           break;
//         case beyondProPlanPriceId:
//           subscriptionPlan = 'beyond_pro';
//           credits = Infinity;
//           break;
//         case beyondUnlimitedPlanPriceId:
//           subscriptionPlan = 'beyondunlimited';
//           credits = Infinity;
//           break;
//         default:
//           alert('Invalid plan ID');
//           navigate('/askquestion');
//           return;
//       }
  
//       const userRef = doc(firestore, 'users', userId);
//       await updateDoc(userRef, {
//         subscriptionPlan: subscriptionPlan,
//         credits: credits,
//         stripeCustomerId: stripeCustomerId, // Store the Stripe customer ID
//       });
  
//       alert('Subscription plan updated successfully');
//       navigate('/');
//     } catch (error) {
//       console.error('Error updating user subscription:', error);
//       alert('An error occurred while updating your subscription. Please try again or contact support.');
//     }
//   }, [priceId, navigate]);

//   useEffect(() => {
//     handleAccept();
//   }, [handleAccept]); // Dependency array for useEffect now includes handleAccept

//   return (
//     <div style={{ textAlign: 'center', marginTop: '50px' }}>
//       <h2>Subscription Success</h2>
//       <p>You have selected the {priceId === proPlanPriceId ? 'Pro' : priceId === beyondProPlanPriceId ? 'Beyond Pro' : 'Beyond Unlimited'} Plan.</p>      <p>Please confirm your subscription to update your account.</p>
//       <button onClick={handleAccept} style={{ cursor: 'pointer', padding: '10px 20px', margin: '10px' }}>
//   Accept {priceId === proPlanPriceId ? 'Pro' : priceId === beyondProPlanPriceId ? 'Beyond Pro' : 'Beyond Unlimited'} Upgrade
// </button>
//       <button onClick={() => navigate('/askquestion')} style={{ cursor: 'pointer', padding: '10px 20px', margin: '10px' }}>
//         Skip for Now
//       </button>
//     </div>
//   );
// };

// export default SuccessPage;






// import React, { useEffect, useCallback } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { doc, updateDoc } from 'firebase/firestore';
// import { firestore, auth } from './firebase';
// import { loadStripe } from '@stripe/stripe-js';

// const stripePromise = loadStripe('pk_live_51OsyIpJcTMg4yEiljrwB32KGtP0ZWTkL6621dIxGZCH6OsSDPQDDyd8Bfj642oGBZ8fpjA9zuJJDOVf8vAEwkvjp00qzk4VySK');

// const SuccessPage = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const searchParams = new URLSearchParams(location.search);
//   const priceId = searchParams.get('plan');

//   const handleAccept = useCallback(async () => {
//     try {
//       const userId = auth.currentUser?.uid;
//       if (!userId) {
//         alert('No user signed in.');
//         navigate('/');
//         return;
//       }

//       const stripe = await stripePromise;
//       const session = await stripe.checkout.sessions.retrieve(location.search.split('session_id=')[1]);
//       const customerId = session.customer;

//       let subscriptionPlan;
//       let credits;

//       switch (priceId) {
//         case 'price_1OtAtyJcTMg4yEiliPyYtJgf':
//           subscriptionPlan = 'pro';
//           credits = 40;
//           break;
//         case 'price_1OtEfVJcTMg4yEil4MbigM91':
//           subscriptionPlan = 'beyond_pro';
//           credits = Infinity;
//           break;
//         default:
//           alert('Invalid plan ID');
//           navigate('/askquestion');
//           return;
//       }

//       const userRef = doc(firestore, 'users', userId);
//       await updateDoc(userRef, {
//         subscriptionPlan: subscriptionPlan,
//         credits: credits,
//         stripeCustomerId: customerId,
//       });

//       alert('Subscription plan updated successfully');
//       navigate('/');
//     } catch (error) {
//       console.error('Error updating user subscription:', error);
//       alert('An error occurred while updating your subscription. Please try again or contact support.');
//     }
//   }, [navigate, priceId, location.search]);

//   useEffect(() => {
//     handleAccept();
//   }, [handleAccept]);

//   return (
//     <div style={{ textAlign: 'center', marginTop: '50px' }}>
//       <h2>Subscription Success</h2>
//       <p>Your subscription has been successfully processed.</p>
//     </div>
//   );
// };

// export default SuccessPage;