import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from './firebase';
import QuestPlay from './QuestPlay';
import styled from 'styled-components';

const SharedQuestContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  position: relative;
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 20px;
`;

const BackButton = styled(Link)`
  position: absolute;
  top: 20px;
  left: 20px;
  text-decoration: none;
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  width: 40px;
  height: 40px;
`;

const SharedQuest = () => {
  const { questId } = useParams();
  const [quest, setQuest] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchQuest = async () => {
      try {
        const questDoc = await getDoc(doc(firestore, 'quests', questId));
        if (questDoc.exists()) {
          setQuest({ id: questDoc.id, ...questDoc.data() });
        } else {
          setError('Quest not found');
        }
      } catch (err) {
        setError('Error fetching quest');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchQuest();
  }, [questId]);

  if (loading) return <SharedQuestContainer><p>Loading...</p></SharedQuestContainer>;
  if (error) return <SharedQuestContainer><p>{error}</p></SharedQuestContainer>;
  if (!quest) return null;

  return (
    <SharedQuestContainer>
      <BackButton to="/">
        <Logo src="/supertutorlogo.png" alt="SuperTutor Logo" />
      </BackButton>
      <Title>{quest.name}</Title>
      <QuestPlay quest={quest} user={null} onBack={() => {}} />
    </SharedQuestContainer>
  );
};

export default SharedQuest;