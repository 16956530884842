// import React, { useState } from 'react';

// const VideoCarousel = () => {
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const videos = [
//     "https://www.youtube.com/embed/a2nsb89OM28",
//     "https://www.youtube.com/embed/0QiwXG74Ky0",
//     "https://www.youtube.com/embed/aNGlsSTg2ko",
//     "https://www.youtube.com/embed/1vGHVn6vZgs",
//     "https://www.youtube.com/embed/nGUwzGTG43Q",
//     "https://www.youtube.com/embed/XdVUA074Xfs",
//     "https://www.youtube.com/embed/HJ6GA4Gody4",

//   ];

//   const slide = (direction) => {
//     if (direction === 'left') {
//       setCurrentIndex(currentIndex > 0 ? currentIndex - 1 : videos.length - 3);
//     } else {
//       setCurrentIndex(currentIndex < videos.length - 3 ? currentIndex + 1 : 0);
//     }
//   };
//   return (
//     <div style={{
//       position: 'relative',
//       maxWidth: '1000px',
//       margin: '20px auto',
//       overflow: 'visible'
//     }}>
//       <div style={{
//         display: 'flex',
//         transition: 'transform 0.3s ease-in-out',
//         transform: `translateX(-${currentIndex * 33.33}%)`,
//         gap: '20px'
//       }}>
//         {videos.map((videoSrc, index) => (
//           <div key={index} style={{
//             minWidth: 'calc(33.33% - 13.33px)',
//             flexShrink: 0,
//             borderRadius: '20px',
//             overflow: 'hidden',
//             boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
//           }}>
//             <div style={{
//               position: 'relative',
//               paddingTop: '177.78%' // 9:16 Aspect Ratio for Shorts
//             }}>
//               <iframe
//                 src={videoSrc}
//                 title={`YouTube video player ${index + 1}`}
//                 frameBorder="0"
//                 allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//                 referrerPolicy="strict-origin-when-cross-origin"
//                 allowFullScreen
//                 style={{
//                   position: 'absolute',
//                   top: 0,
//                   left: 0,
//                   width: '100%',
//                   height: '100%'
//                 }}
//               />
//             </div>
//           </div>
//         ))}
//       </div>
//       <button
//         onClick={() => slide('left')}
//         style={{
//           position: 'absolute',
//           left: '-50px',
//           top: '50%',
//           transform: 'translateY(-50%)',
//           background: 'transparent',
//           border: 'none',
//           borderRadius: '0 50% 50% 0',
//           width: '40px',
//           height: '80px',
//           fontSize: '20px',
//           cursor: 'pointer',
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'center',
//           zIndex: 10
//         }}
//       >
//         <div style={{
//           width: 0,
//           height: 0,
//           borderTop: '10px solid transparent',
//           borderBottom: '10px solid transparent',
//           borderRight: '15px solid #007bff'
//         }} />
//       </button>
//       <button
//         onClick={() => slide('right')}
//         style={{
//           position: 'absolute',
//           right: '-50px',
//           top: '50%',
//           transform: 'translateY(-50%)',
//           background: 'transparent',
//           border: 'none',
//           borderRadius: '50% 0 0 50%',
//           width: '40px',
//           height: '80px',
//           fontSize: '20px',
//           cursor: 'pointer',
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'center',
//           zIndex: 10
//         }}
//       >
//         <div style={{
//           width: 0,
//           height: 0,
//           borderTop: '10px solid transparent',
//           borderBottom: '10px solid transparent',
//           borderLeft: '15px solid #007bff'
//         }} />
//       </button>
//     </div>
//   );
//   };
  
//   export default VideoCarousel;
import React, { useState } from 'react';

const VideoCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const videos = [
    "https://www.youtube.com/embed/a2nsb89OM28",
    "https://www.youtube.com/embed/0QiwXG74Ky0",
    "https://www.youtube.com/embed/aNGlsSTg2ko",
    "https://www.youtube.com/embed/1vGHVn6vZgs",
    "https://www.youtube.com/embed/nGUwzGTG43Q",
    "https://www.youtube.com/embed/XdVUA074Xfs",
    "https://www.youtube.com/embed/HJ6GA4Gody4",
  ];

  const slide = (direction) => {
    if (direction === 'left') {
      setCurrentIndex(currentIndex > 0 ? currentIndex - 1 : videos.length - 1);
    } else {
      setCurrentIndex(currentIndex < videos.length - 1 ? currentIndex + 1 : 0);
    }
  };

  return (
    <div style={{
      position: 'relative',
      maxWidth: '315px', // Adjusted for Shorts aspect ratio
      margin: '20px auto',
      padding: '0 50px', // Space for arrows
    }}>
      <div style={{
        overflow: 'hidden',
        borderRadius: '20px',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
      }}>
        <div style={{
          display: 'flex',
          transition: 'transform 0.3s ease-in-out',
          transform: `translateX(-${currentIndex * 100}%)`,
        }}>
          {videos.map((videoSrc, index) => (
            <div key={index} style={{
              minWidth: '100%',
              flexShrink: 0,
            }}>
              <div style={{
                position: 'relative',
                paddingTop: '177.78%', // 9:16 Aspect Ratio for Shorts
              }}>
                <iframe
                  src={videoSrc}
                  title={`YouTube video player ${index + 1}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%'
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <button
  onClick={() => slide('left')}
  style={{
    position: 'absolute',
    left: '10px',
    top: '50%',
    transform: 'translateY(-50%)',
    background: 'rgb(0, 240, 255)', // Updated background color
    color: 'blue',
    border: 'none',
    borderRadius: '50%',
    width: '30px', // Reduced size
    height: '30px', // Reduced size
    fontSize: '16px', // Smaller font size for the arrow
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease'
  }}
  onMouseEnter={(e) => e.target.style.transform = 'translateY(-50%) scale(1.1)'}
  onMouseLeave={(e) => e.target.style.transform = 'translateY(-50%) scale(1)'}
>
  &#8249;
</button>
      <button
  onClick={() => slide('right')}
  style={{
    position: 'absolute',
    right: '10px',
    top: '50%',
    transform: 'translateY(-50%)',
    background: 'rgb(0, 240, 255)',
    color: 'blue',
    border: 'none',
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    fontSize: '16px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease'
  }}
  onMouseEnter={(e) => e.target.style.transform = 'translateY(-50%) scale(1.1)'}
  onMouseLeave={(e) => e.target.style.transform = 'translateY(-50%) scale(1)'}
>
  &#8250;
</button>
    </div>
  );
};

export default VideoCarousel;