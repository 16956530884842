import React, { useState, useEffect } from 'react';
import { SketchPicker } from 'react-color';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const ColorPickerButton = () => {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState('#f0f8ff');
  const db = getFirestore();
  const auth = getAuth();

  useEffect(() => {
    const fetchColor = async () => {
      const user = auth.currentUser;
      if (user) {
        const docRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists() && docSnap.data().backgroundColor) {
          setBackgroundColor(docSnap.data().backgroundColor);
          document.body.style.backgroundColor = docSnap.data().backgroundColor;
        }
      }
    };
    fetchColor();
  }, [db, auth]);

  const handleColorChange = async (color) => {
    const user = auth.currentUser;
    if (user) {
      setBackgroundColor(color.hex);
      document.body.style.backgroundColor = color.hex;
      await setDoc(doc(db, 'users', user.uid), { backgroundColor: color.hex }, { merge: true });
    }
  };
  return (
    <>
      <button
        className="colorPickerButton"
        onClick={() => setShowColorPicker(!showColorPicker)}
        title="Change background color"
        style={{
          position: 'fixed',
          bottom: '4px',
          left: '4px',
          fontSize: '20px',
          width: '30px',
          height: '30px',
          lineHeight: '10px',
          textAlign: 'center',
          padding: '0',
          background: 'white',
          color: 'black',
          border: 'none',
          cursor: 'pointer',
          zIndex: 9999,
          borderRadius: '50%',
        }}
      >
        🎨
      </button>
      {showColorPicker && (
        <div style={{ position: 'fixed', bottom: '40px', left: '4px', zIndex: 9999 }}>
          <SketchPicker
            color={backgroundColor}
            onChangeComplete={handleColorChange}
          />
        </div>
      )}
    </>
  );
};

export default ColorPickerButton;