// src/components/About.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import m1Image from './m1.jpeg'; // Adjust the path as necessary

import m2Image from './m2.jpg'; // Adjust the path as necessary

import m3Image from './m3.jpg'; // Adjust the path as necessary

import m4Image from './m4.jpg'; // Adjust the path as necessary
import m5Image from './m5.jpg'; // Adjust the path as necessary
import m6Image from './m6.jpg'; // Adjust the path as necessary
import m7Image from './m7.png'; // Adjust the path as necessary

const About = () => {
  const navigate = useNavigate();

//   const techniques = [
//     { name: 'Adaptive AI', icon: '🧠' },
//     { name: 'Interactive Media', icon: '🎭' },
//     { name: 'Gamification', icon: '🎮' },
//     { name: 'Mnemonic Mastery', icon: '🔑' },
//     { name: 'Storytelling', icon: '📚' },
//   ];

  return (
    <div style={{
      padding: '60px 20px',
      maxWidth: '800px',
      margin: '0 auto',
      lineHeight: '1.8',
      position: 'relative',
    }}>
      <button
        onClick={() => navigate('/')}
        style={{
          position: 'fixed',
          top: '20px',
          left: '20px',
          padding: '10px 20px',
          backgroundColor: '#333',
          color: 'white',
          border: 'none',
          borderRadius: '30px',
          cursor: 'pointer',
          zIndex: 1000,
          boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
          transition: 'all 0.3s ease',
        }}
        onMouseOver={(e) => e.target.style.transform = 'scale(1.05)'}
        onMouseOut={(e) => e.target.style.transform = 'scale(1)'}
      >
        ← 
      </button>

      <h1 style={{ fontSize: '42px', textAlign: 'center', marginBottom: '30px', color: '#333', fontWeight: 'bold' }}>
      Welcome to Supertutor
      </h1>

      <p style={{ fontSize: '20px', marginBottom: '25px', lineHeight: '1.6' }}>
      As you know, much of the modern education system is boring, uninspired and ineffective.  
I created Supertutor as a tool to cultivate the most heroic version of yourself. To enable learning while enjoying. To accelerate abundance mindset and achievement. 
      </p>

{/* 
      <h2 style={{ fontSize: '28px', marginTop: '40px', marginBottom: '20px', color: '#444' }}>Our Arsenal of Innovation</h2>

      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginBottom: '40px' }}>
        {techniques.map((tech, index) => (
          <div key={index} style={{
            width: '48%',
            marginBottom: '20px',
            padding: '20px',
            backgroundColor: '#f0f0f0',
            borderRadius: '10px',
            boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
            transition: 'all 0.3s ease',
          }}
          onMouseOver={(e) => e.target.style.transform = 'translateY(-5px)'}
          onMouseOut={(e) => e.target.style.transform = 'translateY(0)'}
          >
            <span style={{ fontSize: '36px', marginRight: '10px' }}>{tech.icon}</span>
            <span style={{ fontSize: '18px', fontWeight: 'bold' }}>{tech.name}</span>
          </div>
        ))}
      </div> */}

      {/* <img src="/2.png" alt="Personalized Learning Experience" style={{ width: '60%', borderRadius: '10px', marginBottom: '30px' }} /> */}

      <p style={{ fontSize: '20px', marginBottom: '25px', lineHeight: '1.6' }}>
Supertutor helps you grow your imagination and take action to realize your vision of the world. Get excited to be alive! To build a great future! To cultivate a love for learning. To achieve your happiness. To become the super version of yourself. 

      </p>

      {/* <img src="/3.png" alt="Problem-Solving Skills" style={{ width: '60%', borderRadius: '10px', marginBottom: '30px' }} /> */}

      {/* <p style={{ fontSize: '20px', marginBottom: '25px', lineHeight: '1.6' }}>
        Dive into a world where problem-solving becomes an art form. Our AI-powered guidance doesn't just provide 
        answers—it illuminates pathways of understanding, ensuring you grasp not just the 'what', but the 'why' 
        and 'how' behind every concept.
      </p> */}
      <img src={m1Image} alt="Supertutor Meme" style={{ width: '60%', borderRadius: '10px', marginBottom: '30px' }} />

         <p style={{ fontSize: '30px', marginBottom: '25px', lineHeight: '1.6' }}>
         Major features of Supertutor: 

      </p>

           <p style={{ fontSize: '20px', marginBottom: '25px', lineHeight: '1.6' }}>
           AI chat: Unlimited access to chat with the best AI model in the world to help with learning new hard skills and doing hard tasks (currently Gpt4o - will continuously be upgraded as the models improve). I have used AI to 10x my productivity in coding, writing, and learning new skills. 
      </p>

      <img src={m2Image} alt="Supertutor Meme" style={{ width: '60%', borderRadius: '10px', marginBottom: '30px' }} />


      <p style={{ fontSize: '20px', marginBottom: '25px', lineHeight: '1.6' }}>
      Goal Setting & Achievement:
Transform your ambitions into reality with our AI-assisted goal-setting feature. Inspired by proven success strategies, this tool helps you:

Set clear, ambitious goals across various life areas.
Break down objectives into actionable steps.
Track progress with measurable milestones.
Adjust targets based on AI-driven insights.

Whether you're aiming to master a new skill or achieve a personal best, our systematic approach turns vague aspirations into concrete results.       </p>

<img src={m3Image} alt="Supertutor Meme" style={{ width: '60%', borderRadius: '10px', marginBottom: '30px' }} />

<p style={{ fontSize: '20px', marginBottom: '25px', lineHeight: '1.6' }}>
Trivia & Greatest Imagination Game: 
A little competitive game helps boost excitement, performance and build friendships! The trivia game challenges your knowledge on any topic. The greatest imagination game challenges players to express themselves creatively, vote for who has the greatest imagination, and compete against AI. Designed to ignite your imagination to be greater. 
   </p>

   <img src={m4Image} alt="Supertutor Meme" style={{ width: '60%', borderRadius: '10px', marginBottom: '30px' }} />


   <p style={{ fontSize: '20px', marginBottom: '25px', lineHeight: '1.6' }}>
   Meme maker: 

Humor is the highest form of intelligence. The most influential humans use memes and humor to express an idea in a way that resonates more than stating it boringly. Supertutor’s meme maker takes any of your words, or topics you’re learning about and makes you original memes. Use the memes for learning, teaching, sharing or just for self pleasure!
  </p>

  <img src={m6Image} alt="Supertutor Meme" style={{ width: '60%', borderRadius: '10px', marginBottom: '30px' }} />

  <p style={{ fontSize: '20px', marginBottom: '25px', lineHeight: '1.6' }}>
  Storytime: 
This button takes your prompt or the conversation you’re having with Supertutor and turns it into a story of a category of your choice: romcom, musical, action…Let’s say i’m learning about how gradient descent works: I can read the textbook definition and with storytime i can put it into a relatable exciting story to enhance my memory and spark my creativity about the narrative application of any concept i'm learning about.Similar to how the broadway show "Hamilton" took a historical book and made it way more relatable, storytime can take any technical topic and make it an adventure to experience.
</p>
<img src={m5Image} alt="Supertutor Meme" style={{ width: '60%', borderRadius: '10px', marginBottom: '30px' }} />


<p style={{ fontSize: '20px', marginBottom: '25px', lineHeight: '1.6' }}>
AI image generation: 
Unlimited Access to DALL-E 3 image generator so you can visualize your imagination in any style of your choosing.
</p>
<img src={m7Image} alt="Supertutor Meme" style={{ width: '60%', borderRadius: '10px', marginBottom: '30px' }} />

      {/* <p style={{ fontSize: '22px', marginBottom: '25px', lineHeight: '1.6', fontStyle: 'italic', textAlign: 'center' }}>
        "Education is not the filling of a pail, but the lighting of a fire." 
      </p> */}

      {/* <img src="/m4.png" alt="Lifelong Learning" style={{ width: '60%', borderRadius: '10px', marginBottom: '30px' }} /> */}

      <p style={{ fontSize: '20px', marginBottom: '25px', lineHeight: '1.6', textAlign: 'center' }}>
      Dive into a world where problem-solving becomes an art form. 
Join Supertutor for the adventure of a lifetime!

      </p>

   
      <button
        className="startButton"
        onClick={(e) => {
          navigate('/askquestion')
        }}
        style={{
          fontSize: '1.7em',
          padding: '10px 20px',
          background: '#98FB98',
          color: 'black',
          border: '2px solid black',
          borderRadius: '15px',
          boxShadow: '3px 3px 0 black',
          transition: 'all 0.2s ease',
        }}
      >
        Get Started
      </button>

      <p style={{ fontSize: '15px', marginBottom: '25px', marginTop: '25px', lineHeight: '1.6' }}>
  Created with ❤️ by Adam Jonah   </p>

</div>

    //   <button onClick={() => navigate('/askquestion')} style={{ display: 'block', margin: '0 auto' }}>Ask a Question</button>

  );
};

export default About;