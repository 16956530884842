import React, { useEffect, useState } from 'react';
import { auth, firestore } from './firebase';
import { doc, getDoc } from 'firebase/firestore';
import star1 from './star1.png'; // Assuming you have star1.png in the same folder
import star2 from './star2.png'; // Assuming you have star2.png in the same folder
const TreasureChestPopup = ({ onClose }) => {

// const TreasureChestPopup = ({ onClose, onCharacterSelected, onOverlayClick }) => {
  const [streak, setStreak] = useState(0);
  // const [selectedCharacter, setSelectedCharacter] = useState('supertutor');
  const [showPopup] = useState(false);
  const [popupMessage] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const user = auth.currentUser;
      if (user) {
        const userRef = doc(firestore, 'users', user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setStreak(userData.streak || 0);
         // setSelectedCharacter(userData.selectedCharacter || 'supertutor');
        }
      }
    };
    fetchData();
  }, []);

  // const toggleCharacter = async (character) => {
  //   const user = auth.currentUser;
  //   if (user) {
  //     if (character === 'profbroc' && streak < 10) {
  //       setPopupMessage('Get your streak to 10 to unlock the memes of Professor Broc:)');
  //       setShowPopup(true);
  //       return;
  //     } else if (character === 'mrmoon' && streak < 20) {
  //       setPopupMessage('Get your streak to 20 to unlock the memes of Mr. Moon:)');
  //       setShowPopup(true);
  //       return;
  //     }

  //     const userRef = doc(firestore, 'users', user.uid);
  //     await updateDoc(userRef, { selectedCharacter: character });
  //     onCharacterSelected(character);
  //   }
  // };

  return (
    <div className="popup-overlay" onClick={onClose} style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
      overflow: 'auto',
    }}>
  
      <div className="popup-content" style={{
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '10px',
        maxWidth: '400px',
        width: '90%',
        textAlign: 'center',
        position: 'relative',
        maxHeight: '80vh',
        overflowY: 'auto',
      }}>

<button 
          onClick={onClose} 
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            background: 'none',
            border: 'none',
            fontSize: '20px',
            cursor: 'pointer',
          }}
        >
          ×
        </button>
        <h3>Learn to Earn Rewards</h3>
        {/* Fun Streak Explanation */}
<div style={{
  background: '#f0f8ff',
  padding: '15px',
  borderRadius: '10px',
  marginTop: '20px',
  marginBottom: '20px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
}}>
  {/* <h4 style={{ color: '#4169e1', marginBottom: '10px' }}>Supertutor Streak Magic:</h4> */}
  <p style={{ fontSize: '14px', lineHeight: '1.5' }}>
    <span role="img" aria-label="fire">🔥</span> Every day you learn, your streak grows by 1!<br/>
    <span role="img" aria-label="muscle">💪</span> Your streak never drops - it only gets bigger!<br/>
    <span role="img" aria-label="star">⭐</span> Keep learning to unlock amazing rewards!<br/>
    <span role="img" aria-label="rocket">🚀</span> How big can you grow your streak?
  </p>
</div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div>
          <p>Your current streak is: {streak}</p>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', gap: '5px', marginBottom: '10px' }}>
    {[...Array(streak)].map((_, index) => (
      <img
        key={index}
        src={index % 2 === 0 ? star1 : star2}
        alt="Star"
        style={{ width: '30px', height: '30px' }}
      />
    ))}
  </div>


  <p>Unlock at 10 Day Streak:</p>

            {/* <img src="/profbroc.png" alt="Professor Broc" style={{ maxWidth: '200px', marginBottom: '10px' }} /> */}
     
            {/* <ul>
              <li>Leonardo DiCaprio Cheers</li>
              <li>Drake Hotline Bling</li>
            </ul> */}

<div style={{ background: '#f4f4f4', padding: '20px', borderRadius: '10px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
  <h2 style={{ textAlign: 'center', color: '#333', marginBottom: '20px' }}>Mystery Meme Templates</h2>
  <ul style={{ listStyle: 'none', padding: '0', fontSize: '18px', lineHeight: '2em' }}>
    <li style={{ background: '#ffadad', padding: '10px', borderRadius: '5px', marginBottom: '10px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', color: '#fff', fontWeight: 'bold' }}>❓❓❓</li>
    <li style={{ background: '#ffd6a5', padding: '10px', borderRadius: '5px', marginBottom: '10px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', color: '#fff', fontWeight: 'bold' }}>❓❓❓</li>
  </ul>
</div>
          </div>
          <div>
          <p>Unlock at 20 Day Streak:</p>

            {/* <img src="/mrmoon.png" alt="Mr. Moon" style={{ maxWidth: '200px', marginTop: '20px', marginBottom: '10px' }} /> */}
            {/* <ul>
              <li>Ancient Aliens</li>
              
            </ul> */}
            <div style={{ background: '#f4f4f4', padding: '20px', borderRadius: '10px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
  <h2 style={{ textAlign: 'center', color: '#333', marginBottom: '20px' }}>Mystery Meme Templates</h2>
  <ul style={{ listStyle: 'none', padding: '0', fontSize: '18px', lineHeight: '2em' }}>
    <li style={{ background: '#ffadad', padding: '10px', borderRadius: '5px', marginBottom: '10px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', color: '#fff', fontWeight: 'bold' }}>❓❓❓</li>
    <li style={{ background: '#ffd6a5', padding: '10px', borderRadius: '5px', marginBottom: '10px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', color: '#fff', fontWeight: 'bold' }}>❓❓❓</li>
  </ul>
</div>
          </div>
        </div>
        {/* <div>
          <p>Select your character:</p>
          <button onClick={() => toggleCharacter('supertutor')} style={{
            padding: '10px 20px',
            fontSize: '16px',
            backgroundColor: selectedCharacter === 'supertutor' ? '#32cd32' : '#f0f0f0',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            marginRight: '10px',
          }}>Supertutor</button>
          <button onClick={() => toggleCharacter('profbroc')} style={{
            padding: '10px 20px',
            fontSize: '16px',
            backgroundColor: selectedCharacter === 'profbroc' ? '#32cd32' : '#f0f0f0',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            marginRight: '10px',
          }}>Supertutor flying</button>
          <button onClick={() => toggleCharacter('mrmoon')} style={{
            padding: '10px 20px',
            fontSize: '16px',
            backgroundColor: selectedCharacter === 'mrmoon' ? '#32cd32' : '#f0f0f0',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}>Supertutor Strong</button>
        </div> */}
        <button onClick={onClose} style={{
          padding: '10px 20px',
          fontSize: '16px',
          backgroundColor: '#f0f0f0',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          marginTop: '20px',
        }}>Close</button>
        {showPopup && (
          <div style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            background: 'rgba(0, 0, 0, 0.8)',
            color: 'white',
            padding: '10px',
            borderRadius: '5px',
            zIndex: 1,
          }}>
            {popupMessage}
          </div>
        )}
      </div>
    </div>
  );
};

export default TreasureChestPopup;
