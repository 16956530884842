import React from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth, firestore } from './firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import axios from 'axios';

const JoinGame = () => {
  const navigate = useNavigate();

  const handleJoinGame = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const userRef = doc(firestore, `users/${user.uid}`);
      const userDoc = await getDoc(userRef);

      if (!userDoc.exists()) {
        // Create a new user document with initial settings
        await setDoc(userRef, {
          name: user.displayName,
          email: user.email,
          subscriptionPlan: "free",
          credits: 6,  // Default credit value
          lastResetDate: new Date().toDateString(),
        });

        // Call cloud function to send a welcome email
        try {
          await axios.post('https://us-central1-aisupertutor.cloudfunctions.net/sendWelcomeEmail2', {
            email: user.email,
            name: user.displayName
          });
        } catch (error) {
          console.error("Failed to send welcome email:", error);
        }
      } else {
        // Update existing user document without overwriting credits
        await setDoc(userRef, {
          name: user.displayName,
          email: user.email,
        }, { merge: true });
      }

      // Navigate to the party game after successful sign-in
      navigate('/partygame');
    } catch (error) {
      console.error("Error signing in with Google:", error);
    }
  };

  return (

<button
  className="startButton"
  onClick={handleJoinGame}
  style={{
    fontSize: '1.7em',
    padding: '10px 20px',
    background: '#98FB98',
    color: 'black',
    border: '2px solid black',
    borderRadius: '15px',
    boxShadow: '3px 3px 0 black',
    transition: 'all 0.2s ease',
  }}
>
Join a Game 
</button>
  );
};

export default JoinGame;




// import React from 'react';
// import { useNavigate } from 'react-router-dom';

// const JoinGame = ({ setIsGuest }) => {
//   const navigate = useNavigate();

//   const handleJoinGame = () => {
//     setIsGuest(true);
//     navigate('/partygame');
//   };

//   return (
//     <button
//       className="startButton"
//       onClick={handleJoinGame}
//       style={{
//         fontSize: '1.2em',
//         padding: '10px 20px',
//         background: '#98FB98',
//         color: 'black',
//         border: '2px solid black',
//         borderRadius: '15px',
//         boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
//       }}
//     >
//       Join a Game as Guest
//     </button>
//   );
// };

// export default JoinGame;