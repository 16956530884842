import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const PageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  font-family: 'Poppins', sans-serif;
  color: #333;
`;

const Header = styled.h1`
  font-size: 3em;
  color: #045AC6;
  text-align: center;
  margin-bottom: 40px;
`;

const Section = styled.section`
  margin-bottom: 40px;
`;

const SubHeader = styled.h2`
  font-size: 2em;
  color: #0077BE;
  margin-bottom: 20px;
`;

const Paragraph = styled.p`
  font-size: 1.1em;
  line-height: 1.6;
  margin-bottom: 20px;
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const ListItem = styled.li`
  font-size: 1.1em;
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative;

  &:before {
    content: "•";
    color: #045AC6;
    font-weight: bold;
    position: absolute;
    left: 0;
  }
`;

const HomeButton = styled(Link)`
  display: inline-block;
  margin: 20px 0;
  padding: 10px 20px;
  background-color: #045AC6;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #034494;
  }
`;

const AITutoring = () => {
  return (
    <PageContainer>
      <Header>AI-Powered Tutoring: The Future of Learning</Header>
      <Section>
        <Paragraph>
          Artificial Intelligence is revolutionizing the way we learn. At SUPERTUTOR AI, we're at the forefront of this educational revolution, providing cutting-edge AI tutoring that adapts to your unique learning style and needs.
        </Paragraph>
      </Section>
      
      <Section>
        <SubHeader>How AI Tutoring Works</SubHeader>
        <Paragraph>
          Our AI tutoring system uses advanced machine learning algorithms to analyze your learning patterns, identify areas for improvement, and create personalized learning paths. Here's how it works:
        </Paragraph>
        <List>
          <ListItem>Initial assessment to understand your current knowledge level</ListItem>
          <ListItem>Continuous analysis of your learning progress and style</ListItem>
          <ListItem>Dynamic adjustment of content difficulty and presentation</ListItem>
          <ListItem>Real-time feedback and explanations tailored to your understanding</ListItem>
        </List>
      </Section>
      
      <Section>
        <SubHeader>Benefits of AI Tutoring</SubHeader>
        <List>
          <ListItem>Personalized learning paths that adapt to your progress</ListItem>
          <ListItem>24/7 availability - learn anytime, anywhere</ListItem>
          <ListItem>Instant feedback to reinforce learning</ListItem>
          <ListItem>Adaptive difficulty levels to keep you challenged and engaged</ListItem>
          <ListItem>Comprehensive progress tracking and analytics</ListItem>
        </List>
      </Section>
      
      <Section>
        <SubHeader>Real-world Success Stories</SubHeader>
        <Paragraph>
          Students using SUPERTUTOR AI have reported significant improvements in their academic performance. On average, our users see a 30% increase in test scores and a 40% reduction in study time. Here are some of their stories:
        </Paragraph>
        {/* Add testimonials or success stories here */}
      </Section>
      
      <HomeButton to="/">Return to Home</HomeButton>
    </PageContainer>
  );
};

export default AITutoring;