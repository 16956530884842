import React from 'react';
import styled from 'styled-components';

const PopupContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  z-index: 1000;
`;

const Button = styled.button`
  margin: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
`;

const SharePopup = ({ quest, shareLink, onClose }) => {
    console.log("SharePopup received quest:", quest);
  
    if (!quest || typeof quest !== 'object' || !quest.name) {
      console.error("Invalid quest object received:", quest);
      return (
        <PopupContainer>
          <CloseButton onClick={onClose}>&times;</CloseButton>
          <h2>Error</h2>
          <p>Unable to share quest. Invalid quest data.</p>
        </PopupContainer>
      );
    }
  
    const questName = quest.name;
    const shareMessage = `I created this SuperTutor learning quest about "${questName}". Click here to play: ${shareLink}`;
  
    const handleEmailShare = () => {
      const emailSubject = encodeURIComponent(`Check out my SuperTutor Quest: ${quest.name}`);
      const emailBody = encodeURIComponent(shareMessage);
      window.open(`mailto:?subject=${emailSubject}&body=${emailBody}`);
    };
  
    const handleTextShare = () => {
      if (navigator.share) {
        navigator.share({
          title: `SuperTutor Quest: ${quest.name}`,
          text: shareMessage,
          url: shareLink,
        }).catch(console.error);
      } else {
        alert('Text sharing is not supported on this device. You can copy the link manually.');
      }
    };
  
    return (
      <PopupContainer>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <h2>Share Your Quest</h2>
        <p>{shareMessage}</p>
        <Button onClick={handleEmailShare}>Share via Email</Button>
        <Button onClick={handleTextShare}>Share via Text</Button>
      </PopupContainer>
    );
  };
  
  export default SharePopup;