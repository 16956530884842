import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { firestore } from './firebase'; // Adjust the import path as needed
import Confetti from 'react-confetti';
import YouTube from 'react-youtube'; // Add this import

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
`;

 
const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  max-width: 100%;
  margin-bottom: 20px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;


const ActionButton = styled.button`
  padding: 12px 24px;
  font-size: 18px;
  background-color: #2196f3;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #1976d2;
  }
`;


const QuizContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  &:hover {
    color: #777;
  }
`;

const Title = styled.h1`
  font-size: 28px;
  color: #333;
  margin-bottom: 24px;
  text-align: center;
`;

const MediaContainer = styled.div`
  margin-top: 20px;
  max-width: 100%;
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
`;


const Question = styled.h2`
  font-size: 22px;
  color: #444;
  margin-bottom: 24px;
  text-align: left;
  white-space: pre-wrap;

  p {
    margin: 0;
    text-indent: 1.5em;
  }

  p:first-child {
    text-indent: 0;
  }
`;
const AnswerGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-bottom: 24px;
`;

const AnswerButton = styled.button`
  padding: 16px;
  font-size: 18px;
  background-color: #e0e0e0;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${props => props.answered ? '#e0e0e0' : '#d0d0d0'};
  }

  ${props => props.answered && props.correct && `
    background-color: #4caf50;
    color: white;
  `}

  ${props => props.answered && props.selected && !props.correct && `
    background-color: #f44336;
    color: white;
  `}

  ${props => props.selected && !props.answered && `
    background-color: #2196f3;
    color: white;
  `}
`;

const Explanation = styled.div`
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
  margin-top: 24px;
  font-size: 16px;
  line-height: 1.5;
  text-align: left; // Add this line
  white-space: pre-wrap; // Add this line
`;

// const NextButton = styled.button`
//   padding: 12px 24px;
//   font-size: 18px;
//   background-color: #2196f3;
//   color: white;
//   border: none;
//   border-radius: 8px;
//   cursor: pointer;
//   transition: all 0.3s ease;
//   margin-top: 24px;

//   &:hover {
//     background-color: #1976d2;
//   }
// `;

const ResultMessage = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  color: ${props => props.correct ? '#4caf50' : '#f44336'};
`;

const QuestPlay = ({ quest, user, onBack }) => {
  const [currentLevel, setCurrentLevel] = useState(quest.startLevel || 1);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questions, setQuestions] = useState([]);
  // const [incorrectQuestions, setIncorrectQuestions] = useState([]);
  // const [levelCompleted, setLevelCompleted] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [answered, setAnswered] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [wrongAnswers, setWrongAnswers] = useState([]);
  const [anonymousProgress, setAnonymousProgress] = useState({});
  const [userProgress, setUserProgress] = useState({});

  // useEffect(() => {
  //   if (quest && quest.levels) {
  //     const level = quest.levels.find(lvl => lvl.levelNumber === currentLevel);
  //     if (level) {
  //       setQuestions(level.questions);
  //       setWrongAnswers([]);
  //       setCurrentQuestionIndex(0);
  //     }
  //   }
  // }, [quest, currentLevel]);
    // Modify the useEffect to use anonymousProgress for non-logged-in users
    useEffect(() => {
      const fetchUserProgress = async () => {
        if (user) {
          // Fetch progress for authenticated users from Firestore
          const userRef = doc(firestore, 'users', user.uid);
          const userDoc = await getDoc(userRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setUserProgress(userData.gameProgress || {});
          }
        } else {
          // Use local state for anonymous users
          setUserProgress(anonymousProgress);
        }
      };
  
      fetchUserProgress();
    }, [user, anonymousProgress]);

      // Use userProgress to determine if the current level is completed
  const isLevelCompleted = userProgress[quest.id]?.levelsCompleted.includes(currentLevel);

  
    useEffect(() => {
      if (quest && quest.levels) {
        const level = quest.levels.find(lvl => lvl.levelNumber === currentLevel);
        if (level) {
          setQuestions(level.questions);
          setWrongAnswers([]);
          setCurrentQuestionIndex(0);
        }
      }
    }, [quest, currentLevel]);

  const handleAnswer = (selectedAnswer) => {
    setSelectedAnswer(selectedAnswer);
    setAnswered(true);
  
    const currentQuestion = questions[currentQuestionIndex];
    const correctAnswerIndex = Number(currentQuestion.correctAnswer) - 1;
    const correct = selectedAnswer === currentQuestion.options[correctAnswerIndex];
    setIsCorrect(correct);
  
    if (!correct) {
      setWrongAnswers(prev => [...prev, currentQuestionIndex]);
    }
  };


  const renderMedia = () => {
    const currentQuestion = questions[currentQuestionIndex];
    if (!currentQuestion.media || !currentQuestion.media.type) return null;
  
    if (currentQuestion.media.type === 'image') {
      return (
        <MediaContainer>
          <Image src={currentQuestion.media.content} alt="Question media" />
        </MediaContainer>
      );
    } else if (currentQuestion.media.type === 'youtube') {
      const videoId = extractYouTubeId(currentQuestion.media.content);
      return (
        <MediaContainer>
          <VideoWrapper>
            <YouTube 
              videoId={videoId} 
              opts={{ 
                width: '100%',
                height: '100%',
                playerVars: {
                  autoplay: 0,
                },
              }} 
            />
          </VideoWrapper>
        </MediaContainer>
      );
    }
  };

  const extractYouTubeId = (url) => {
    // eslint-disable-next-line no-useless-escape
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : null;
  };
  const handleNext = async () => {
    if (currentQuestionIndex + 1 < questions.length) {
      // More questions in this level
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setSelectedAnswer(null);
      setAnswered(false);
    } else if (wrongAnswers.length > 0) {
      // Finished all questions, but some were wrong
      setCurrentQuestionIndex(0);
      setQuestions(questions.filter((_, index) => wrongAnswers.includes(index)));
      setWrongAnswers([]);
      setSelectedAnswer(null);
      setAnswered(false);
    } else if (!isLevelCompleted) {
      // All questions answered correctly and level not yet completed
      await markLevelCompleted();
      if (currentLevel < quest.levels.length) {
        setShowConfetti(true);
        setTimeout(() => {
          setShowConfetti(false);
          setCurrentLevel(prevLevel => prevLevel + 1);
          setCurrentQuestionIndex(0);
          setWrongAnswers([]);
          setSelectedAnswer(null);
          setAnswered(false);
          // Load questions for the next level
          const nextLevelQuestions = quest.levels[currentLevel].questions;
          setQuestions(nextLevelQuestions);
        }, 3000); // Show confetti for 3 seconds
      } else {
        // If it's the last level, close the modal
        onBack();
      }
    } else {
      // Level already completed, move to next level or close
      if (currentLevel < quest.levels.length) {
        setCurrentLevel(prevLevel => prevLevel + 1);
        setCurrentQuestionIndex(0);
        setWrongAnswers([]);
        setSelectedAnswer(null);
        setAnswered(false);
        const nextLevelQuestions = quest.levels[currentLevel].questions;
        setQuestions(nextLevelQuestions);
      } else {
        onBack();
      }
    }
  };
  // const handleNext = async () => {
  //   if (currentQuestionIndex + 1 < questions.length) {
  //     // More questions in this level
  //     setCurrentQuestionIndex(currentQuestionIndex + 1);
  //     setSelectedAnswer(null);
  //     setAnswered(false);
  //   } else if (wrongAnswers.length > 0) {
  //     // Finished all questions, but some were wrong
  //     setCurrentQuestionIndex(0);
  //     setQuestions(questions.filter((_, index) => wrongAnswers.includes(index)));
  //     setWrongAnswers([]);
  //     setSelectedAnswer(null);
  //     setAnswered(false);
  //   } else {
  //     // All questions answered correctly
  //     await markLevelCompleted();
  //     if (currentLevel < quest.levels.length) {
  //       setShowConfetti(true);
  //       setTimeout(() => {
  //         setShowConfetti(false);
  //         setCurrentLevel(prevLevel => prevLevel + 1);
  //         setCurrentQuestionIndex(0);
  //         setWrongAnswers([]);
  //         setSelectedAnswer(null);
  //         setAnswered(false);
  //         // Load questions for the next level
  //         const nextLevelQuestions = quest.levels[currentLevel].questions;
  //         setQuestions(nextLevelQuestions);
  //       }, 3000); // Show confetti for 3 seconds
  //     } else {
  //       // If it's the last level, close the modal
  //       onBack();
  //     }
  //   }
  // };
  const markLevelCompleted = async () => {
    if (user) {
      // Update progress in Firestore for authenticated users
      const userRef = doc(firestore, 'users', user.uid);
      const userDoc = await getDoc(userRef);
      let gameProgress = userDoc.exists() ? (userDoc.data().gameProgress || {}) : {};
    
      if (!gameProgress[quest.id]) {
        gameProgress[quest.id] = { levelsCompleted: [] };
      }
    
      if (!gameProgress[quest.id].levelsCompleted.includes(currentLevel)) {
        gameProgress[quest.id].levelsCompleted.push(currentLevel);
        await setDoc(userRef, { gameProgress }, { merge: true });
        setUserProgress(gameProgress);
      }
    } else {
      // Update progress in local state for anonymous users
      setAnonymousProgress(prev => ({
        ...prev,
        [quest.id]: {
          levelsCompleted: [...(prev[quest.id]?.levelsCompleted || []), currentLevel]
        }
      }));
    }
  };


  if (!questions.length) return <QuizContainer>Loading...</QuizContainer>;

  const currentQuestion = questions[currentQuestionIndex];
  return (
    <QuizContainer>
      {showConfetti && <Confetti />}
      <CloseButton onClick={onBack}>&times;</CloseButton>
      <Title>{quest.name} - Level {currentLevel}</Title>
      <Question>{currentQuestion.question.split('\n').map((line, index) => (
  <React.Fragment key={index}>
    {line}
    {index < currentQuestion.question.split('\n').length - 1 && <br />}
  </React.Fragment>
))}</Question>      {answered && (
        <ResultMessage correct={isCorrect}>
          {isCorrect ? "Correct!" : "Incorrect!"}
        </ResultMessage>
      )}
      <AnswerGrid>
        {currentQuestion.options.map((option, index) => (
          <AnswerButton
            key={index}
            onClick={() => !answered && handleAnswer(option)}
            selected={selectedAnswer === option}
            correct={index === Number(currentQuestion.correctAnswer) - 1}
            answered={answered}
            disabled={answered}
          >
            {option}
          </AnswerButton>
        ))}
      </AnswerGrid>
      {wrongAnswers.length > 0 && currentQuestionIndex === 0 && (
  <ResultMessage>
    Redo the questions you got wrong in order to pass this level
  </ResultMessage>
)}
        {answered && (
        <>
          <Explanation>
            <strong>Explanation:</strong> {currentQuestion.explanation}
          </Explanation>
          {renderMedia()}
        </>
      )}
      {answered && (
        <ButtonContainer>
          {isCorrect ? (
           <ActionButton onClick={handleNext}>
           {currentQuestionIndex + 1 < questions.length 
             ? "Next Question" 
             : wrongAnswers.length > 0
               ? "Redo incorrect questions"
               : currentLevel < quest.levels.length 
                 ? "Level Up" 
                 : "Complete Quest"}
         </ActionButton>
          ) : (
            <ActionButton onClick={handleNext}>Next Question</ActionButton>
          )}
        </ButtonContainer>
      )}
  {/* {isLevelCompleted && (
        <CompletedMessage>Level Completed! You can review or move to the next level.</CompletedMessage>
      )} */}
      
    </QuizContainer>
  );
};

export default QuestPlay;