import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const PageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  font-family: 'Poppins', sans-serif;
  color: #333;
`;

const Header = styled.h1`
  font-size: 3em;
  color: #045AC6;
  text-align: center;
  margin-bottom: 40px;
`;

const Section = styled.section`
  margin-bottom: 40px;
`;

const SubHeader = styled.h2`
  font-size: 2em;
  color: #0077BE;
  margin-bottom: 20px;
`;

const Paragraph = styled.p`
  font-size: 1.1em;
  line-height: 1.6;
  margin-bottom: 20px;
`;

const MemeGrid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin-bottom: 40px;
`;


const MemeImage = styled.img`
  width: 40%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 10px;
`;

const HomeButton = styled(Link)`
  display: inline-block;
  margin: 20px 0;
  padding: 10px 20px;
  background-color: #045AC6;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #034494;
  }
`;

const EducationalMemes = () => {
  const memeExamples = [
    { image: "/2.png" },
    { image: "/3.png" },
    { image: "/4.png" }
  ];

  return (
    <PageContainer>
      <Header>Custom Educational Memes</Header>
      <Section>
        <Paragraph>
          Enhance your learning with custom educational memes from SUPERTUTOR AI. Boost memory retention and make learning fun!
        </Paragraph>
      </Section>
      
      <Section>
        <SubHeader>Why Memes for Education?</SubHeader>
        <Paragraph>
          Visual aids are proven to give you an 83% increase in memory retention. Our custom memes combine humor with educational content to create memorable learning experiences. Here's how memes can supercharge your learning:
        </Paragraph>
        <ul>
          <li>Increase engagement and motivation</li>
          <li>Improve information recall</li>
          <li>Simplify complex concepts</li>
          <li>Create emotional connections to content</li>
          <li>Encourage sharing and discussion of learned material</li>
        </ul>
      </Section>

      <Section>
  {/* <SubHeader>Meme Examples</SubHeader> */}
  <MemeGrid>
    {memeExamples.map((meme, index) => (
      <MemeImage key={index} src={meme.image} alt={`Educational Meme ${index + 1}`} />
    ))}
  </MemeGrid>
</Section>

      <Section>
        <SubHeader>How SUPERTUTOR AI Creates Custom Memes</SubHeader>
        <Paragraph>
          Our advanced AI analyzes your learning content and personal preferences to generate custom memes that are both relevant and entertaining. The process involves:
        </Paragraph>
        <ol>
          <li>Content analysis to identify key concepts</li>
          <li>Matching concepts with appropriate meme templates</li>
          <li>Generating witty and relevant captions</li>
          <li>Personalizing memes based on your learning style and humor preferences</li>
          <li>Continuous improvement through user feedback and engagement metrics</li>
        </ol>
      </Section>
      
      <HomeButton to="/">Return to Home</HomeButton>
    </PageContainer>
  );
};

export default EducationalMemes;